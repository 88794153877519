import { Response } from "miragejs";
import { routeTimeout } from "../utils";

export default function routes(server) {
  server.get(
    "/predefined-prompts/",
    (schema, request) => {
      const raw_is_public = (request.queryParams.is_public ?? "").toLowerCase();
      const is_public = raw_is_public
        ? raw_is_public === "true" || raw_is_public === "1"
        : undefined;
      const category_ids = (request.queryParams.category_ids ?? "")
        .split(",")
        .filter(Boolean);

      const search = (request.queryParams.search ?? "").toLowerCase();
      const page = Number(request.queryParams.page ?? 1);
      const pageSize = Number(request.queryParams.pageSize ?? 10);
      const filtered = schema
        .where("predefined-prompt", (c) =>
          c.title.toLowerCase().includes(search),
        )
        .filter((c) =>
          is_public === undefined ? true : c.is_public === is_public,
        )
        .filter((c) =>
          category_ids.length === 0
            ? true
            : category_ids.includes(c.category_id),
        )
        // reverse
        .sort(() => -1);

      const fromIndex = (page - 1) * pageSize;
      return filtered.slice(fromIndex, fromIndex + pageSize);
    },
    routeTimeout(100),
  );

  server.get(
    "/predefined-prompts/:id/",
    (schema, request) => {
      const id = request.params.id;
      const record = schema.find("predefined-prompt", id);
      if (!record) {
        return new Response(404, {}, { detail: "Not found" });
      }

      return record;
    },
    routeTimeout(200),
  );

  server.post(
    "/predefined-prompts",
    (schema, request) => {
      const user = schema.first("user");
      if (!user) {
        return new Response(401, {}, { detail: "Not logged in" });
      }

      const payload = {
        ...JSON.parse(request.requestBody),
        created_by: user.id,
      };

      return server.create("predefined-prompt", payload);
    },
    routeTimeout(2000),
  );

  server.patch(
    "/predefined-prompts/:id",
    (schema, request) => {
      const id = request.params.id;
      const record = schema.find("predefined-prompt", id);
      if (!record) {
        return new Response(404, {}, { detail: "Not found" });
      }

      const updates = JSON.parse(request.requestBody);

      return record.update(updates);
    },
    routeTimeout(2000),
  );

  server.delete(
    "/predefined-prompts/:id",
    (schema, request) => {
      const id = request.params.id;
      const record = schema.find("predefined-prompt", id);
      if (!record) {
        return new Response(404, {}, { detail: "Not found" });
      }

      return record.destroy();
    },
    routeTimeout(2000),
  );
}
