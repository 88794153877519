import { useQuery } from "@tanstack/react-query";
import { create, windowScheduler, keyResolver } from "@yornaath/batshit";
import { authRequest, baseUrl } from "./NetworkUtils";

const users = create({
  fetcher: async (ids) => {
    const response = await authRequest(
      `${baseUrl}/api/users/?user_ids=${ids.join(",")}`,
    );

    return response.results;
  },
  resolver: keyResolver("id"),
  scheduler: windowScheduler(10),
});

export const useUser = (id) => {
  return useQuery({
    queryKey: ["users", id],
    queryFn: () => {
      return id ? users.fetch(id) : null;
    },
  });
};
