// borrow with love from https://github.com/framer/motion/discussions/1884#discussioncomment-5861808
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

const DynamicHeightDiv = ({
  children,
  className,
  transition = { duration: 0.3 },
  onAnimationIteration,
}) => {
  const containerRef = useRef(null);
  const [height, setHeight] = useState("auto");

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect();
      };
    }
  }, []);

  const style = {
    height,
    overflow: "hidden",
    boxSizing: "content-box",
  };

  return (
    <motion.div
      className={className}
      style={style}
      animate={{ height }}
      transition={transition}
      onUpdate={() => {
        if (onAnimationIteration) {
          onAnimationIteration();
        }
      }}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  );
};

export default DynamicHeightDiv;
