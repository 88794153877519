import React, { useState, useEffect } from "react";
import { parse, compile, paramNames } from "../../utils/instructions-query";

import "./ChatStarterForm.scss";
import InlineParam from "./InlineParam";
import SenderLayout from "../SenderLayout";

const scope = `PromptsStarterForm`;

function PromptsStarterForm({ data, submit, className, ...props }) {
  const [model, setModel] = useState({
    id: null,
    title: null,
    description: null,
    instructions: null,
    files: [],
    use_internal_data: false,
    is_public: false,
    category_id: null,
    ...data,
  });

  useEffect(() => {
    setModel(data);
  }, [data]);

  const parsedInstructions = parse(model.instructions);

  const parametersNames = paramNames(parsedInstructions);

  const [params, setParams] = useState(
    parametersNames.reduce((acc, name) => {
      acc[name] = "";
      return acc;
    }, {}),
  );

  const onSubmit = async () => {
    const compiledInstructions = compile(parsedInstructions, params);

    return await submit(compiledInstructions);
  };

  // Find the first empty variable to focus on it
  // when clicking on the SenderLayout label
  const autoFocusableInputId = parsedInstructions.reduce(
    (id, [type, content], i) => {
      if (!id && type === "variable" && params[content] === "") {
        return buildUniqInputId(content, i);
      }

      return id;
    },
    null,
  );

  return (
    <SenderLayout
      className={`${className ?? ""} ${scope}`}
      onSubmit={onSubmit}
      labelFor={autoFocusableInputId}
      {...props}
    >
      <code aria-label={`Instructions`}>
        {parsedInstructions.map(([type, content], i) => {
          if (type === "variable") {
            const value = params[content];

            return (
              <InlineParam
                key={`${content}-${i}`}
                className={`${scope}-param`}
                name={content}
                value={value}
                onChange={(value) => setParams({ ...params, [content]: value })}
                inputId={buildUniqInputId(content, i)}
              />
            );
          }

          return content;
        })}
      </code>
    </SenderLayout>
  );
}

export default PromptsStarterForm;

function buildUniqInputId(name, i) {
  return `${name}-${i}`;
}
