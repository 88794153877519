import { authProvider } from "../utils/auth";
import { ReactComponent as LogoutIcon } from "../icons/box-arrow-right.svg";

import "./LogoutButton.scss";

const scope = "LogoutButton";

export default function LogoutButton({ className, children, ...props }) {
  const logout = () => {
    authProvider.logout();

    window.location.reload();
  };

  return (
    <button
      className={`${className} ${scope}`}
      title="Log out"
      onClick={logout}
      {...props}
    >
      <LogoutIcon />

      {children}
    </button>
  );
}
