import auth from "./routes/auth";
import questions from "./routes/questions";
import conversations from "./routes/conversations";
import answers from "./routes/answers";
import chatbotModels from "./routes/chatbot-models";
import agreement from "./routes/agreement";
import predefinedPrompts from "./routes/predefined-prompts";
import predefinedPromptCategories from "./routes/predefined-prompt-categories";
import users from "./routes/users";

export default function routes(server) {
  server.urlPrefix = "http://localhost:8000";

  // Authentication
  server.namespace = "";

  auth(server);
  agreement(server);

  // Authorized API
  server.namespace = "api";

  conversations(server);
  questions(server);
  answers(server);
  chatbotModels(server);
  predefinedPrompts(server);
  predefinedPromptCategories(server);
  users(server);
}
