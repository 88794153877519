import { createServer, Model, hasMany, belongsTo } from "miragejs";
import ApplicationIdentityManager from "./identity-manager";
import AppSerializer from "./serializer";
import QuestionSerializer from "./serializers/question";
import AnswerSerializer from "./serializers/answer";
import {
  ConversationFactory,
  QuestionFactory,
  AnswerFactory,
  PredefinedPromptFactory,
  PredefinedPromptCategoryFactory,
  UserFactory,
} from "./factories";
import routes from "./routes";
import { defaultScenario } from "./scenarios/default";

const AnswerModel = Model.extend({
  question: belongsTo("question"),
});

const QuestionModel = Model.extend({
  answers: hasMany("answer"),
});

export function makeServer({ environment = "test" } = {}) {
  let server = createServer({
    environment,

    models: {
      answer: AnswerModel,
      agreement: Model,
      question: QuestionModel,
      conversation: Model,
      predefinedPromptCategory: Model,
      predefinedPrompt: Model,
      chatbot_model: Model,
      user: Model,
    },

    factories: {
      answer: AnswerFactory,
      question: QuestionFactory,
      conversation: ConversationFactory,
      predefinedPromptCategory: PredefinedPromptCategoryFactory,
      predefinedPrompt: PredefinedPromptFactory,
      user: UserFactory,
    },

    serializers: {
      application: AppSerializer,
      question: QuestionSerializer,
      answer: AnswerSerializer,
    },

    identityManagers: {
      application: ApplicationIdentityManager,
    },

    seeds(server) {
      const scenarioConfig = localStorage.getItem("mirage.scenario");
      if (scenarioConfig) {
        const scenario = JSON.parse(scenarioConfig);
        scenario.forEach((item) => {
          server.create(item.model, item.attrs);
        });
      } else {
        defaultScenario(server);
      }
    },

    routes() {
      return routes(this);
    },
  });

  return server;
}
