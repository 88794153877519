import React, { useState, useEffect } from "react";
import PromptsForm from "../../components/Prompts/Form";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import TileAnimateable from "../../components/TileAnimateable";
import { authRequest, baseUrl } from "../../utils/NetworkUtils";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ReactComponent as XIcon } from "../../icons/x-lg.svg";
import { authProvider } from "../../utils/auth";

import "./EditPage.scss";

export function loader(route) {
  return {
    promise: authRequest(
      `${baseUrl}/api/predefined-prompts/${route.params.id}/`,
    ),
    id: route.params.id,
  };
}

const scope = "PromptsEditPage";

function PromptsEditPage() {
  const queryClient = useQueryClient();
  const [loaderModel] = useState(useLoaderData());
  const navigate = useNavigate();
  const location = useLocation();
  const [newPrompt] = useState(location.state?.newPrompt);
  const [isTransitionFromNew] = useState(Boolean(newPrompt));
  const [predefinedPrompt, setPredefinedPrompt] = useState(newPrompt);
  const [returnUrl] = useState(
    newPrompt
      ? `/prompts`
      : loaderModel
        ? `/prompts/${loaderModel.id}`
        : // somehow due to animations when leave the model becomes null when leaving the route
          // TODO: figure out how to avoid null model. It just does not make sense.
          null,
  );

  useEffect(() => {
    loaderModel?.promise.then((data) => {
      if (data.created_by !== authProvider.user?.id) {
        throw new Error("You are not allowed to edit this prompt.");
      }

      setPredefinedPrompt(data);
    });
  });

  const doDelete = async () => {
    if (!window.confirm("Are you sure you want to delete this prompt?")) {
      return;
    }

    await authRequest(
      `${baseUrl}/api/predefined-prompts/${predefinedPrompt.id}/`,
      {
        method: "DELETE",
      },
    );

    toast.success("Prompt has been deleted successfully.", {
      theme: "dark",
      position: "top-center",
    });

    queryClient.setQueryData(["predefined-prompts"], (data) => ({
      pages: data.pages.map((page) => {
        return page
          .map((item) => {
            if (item.id === predefinedPrompt.id) {
              return null;
            }

            return item;
          })
          .filter(Boolean);
      }),
      pageParams: data.pageParams,
    }));

    navigate(`/prompts/`);
  };

  const onSubmit = async (data) => {
    const updates = await authRequest(
      `${baseUrl}/api/predefined-prompts/${predefinedPrompt.id}/`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
      },
    );

    toast.success("Prompt has been updated successfully.", {
      theme: "dark",
      position: "top-center",
    });

    queryClient.setQueryData(["predefined-prompts"], (data) => ({
      pages: data.pages.map((page) => {
        return page.map((item) => {
          if (item.id === predefinedPrompt.id) {
            return {
              ...item,
              ...updates,
            };
          }

          return item;
        });
      }),
      pageParams: data.pageParams,
    }));
  };

  // due to animation + router issues when leaving the page
  // we have an empty model, so we can't render real data in such cases
  if (!predefinedPrompt) {
    return null;
  }

  return (
    <TileAnimateable
      key={`PredefinedPrompt-editPage${predefinedPrompt.id}`}
      title={"Edit Template"}
      returnUrl={returnUrl}
      skipAnimation={true || isTransitionFromNew}
      BackIcon={
        <XIcon
          role="img"
          className={`${scope}-backIcon`}
          style={{ scale: 1.5 }}
        />
      }
    >
      <PromptsForm
        data={predefinedPrompt}
        onSubmit={onSubmit}
        onDelete={doDelete}
        key={`PredefinedPrompt-editForm${predefinedPrompt.id}`}
      />
    </TileAnimateable>
  );
}

export default PromptsEditPage;
