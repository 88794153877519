import React, {
  useCallback,
  useState,
  useEffect,
  Suspense,
  useMemo,
} from "react";
import { baseUrl, authRequest } from "../utils/NetworkUtils";
import Tile from "../components/Tile";
import InfiniteScroll from "react-infinite-scroller";
import {
  useSearchParams,
  useOutlet,
  useLocation,
  useLoaderData,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "../components/Logo";
import { ReactComponent as PlusIcon } from "../icons/plus.svg";
import debounce from "lodash.debounce";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import Search from "../components/Overview/Search";
import ActionsDropdown from "../components/ActionsDropdown";
import Loader from "../components/Loader";
import navItems, { shouldHide as shouldHideAppNav } from "../utils/navigation";
import { useMediaQuery } from "@react-hook/media-query";
import LogoutButton from "../components/LogoutButton";
import LigaOS from "ln-ember-toolkit/addon/utils/liga-os";

import "./Overview.scss";

const scope = "Overview";

const pageSize = 15;
// 1-based indexing is encouraged by the API
const firstPage = 1;

const hasLigaOS = LigaOS.isLigaOsPresent();

// This loader is needed to reset the current search results
// after a new conversation is created.
// see the `shouldRevalidate` logic in index.js
export function initialDataLoader() {
  return [];
}

function Overview() {
  const isMobile = useMediaQuery("only screen and (max-width: 768px)");
  const [scrollableElement, setScrollableElement] = useState(null);
  const [hideHeader, setHideHeader] = useState(null);
  const initialData = useLoaderData();
  const currentOutlet = useOutlet();
  const [qps, setQps] = useSearchParams();
  const [search, setSearch] = useState(qps.get("search") || "");
  const location = useLocation();
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);

  const { data: chatbotModels } = useQuery({
    queryKey: ["chatbot-models"],
    queryFn: async () => {
      const res = await authRequest(`${baseUrl}/api/chatbot-models/`);

      return res.results;
    },
  });

  const load = async (queryContext) => {
    const searchString = new URLSearchParams({
      page: queryContext.pageParam,
      pageSize,
      ...(search ? { search } : undefined),
    });

    const data = await authRequest(
      `${baseUrl}/api/conversations/?${searchString}`,
    );
    return data.results;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
    isRefetching,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["conversations"],
    queryFn: load,
    initialPageParam: firstPage,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPageParam === undefined) {
        return firstPage;
      }

      if (lastPage.length < pageSize) {
        return undefined;
      }

      return lastPageParam + 1;
    },
  });

  const navOptions = chatbotModels
    ?.filter((model) => {
      return model.visible !== false;
    })
    .map((model) => {
      const shouldHighlight = Boolean(model.use_internal_data);

      return {
        to: "/chats/new",
        state: { modelVersion: model.id },
        label: model.name,
        ...(shouldHighlight ? { variants: ["highlight"] } : null),
        icon: PlusIcon,
      };
    })
    .concat(isMobile && !shouldHideAppNav ? [{ options: navItems() }] : []);

  const reloadLater = useMemo(() => debounce(refetch, 500), [refetch]);
  useEffect(() => {
    return () => {
      reloadLater.cancel();
    };
  }, [reloadLater, refetch]);

  const conversations = useMemo(() => {
    return data?.pages.flatMap((page) => page) ?? initialData;
  }, [data, initialData]);

  const onScroll = useCallback(
    (e) => {
      setPreviousScrollTop(e.target.scrollTop);

      if (e.target.scrollTop - previousScrollTop < 0) {
        setHideHeader(false);
      } else {
        const reachedScreenThreshold = e.target.scrollTop > 200;

        setHideHeader(reachedScreenThreshold);
      }
    },
    [previousScrollTop, setPreviousScrollTop, setHideHeader],
  );

  const onSearch = (value) => {
    setSearch(value);
    setQps({ search: value ?? "" }, { preventScrollReset: true });
    reloadLater();
  };

  return (
    <>
      <section
        className={scope}
        data-no-side-controls={hideHeader ? true : null}
        data-mobile={isMobile ? true : null}
      >
        <div
          className={`${scope}-scrollable`}
          ref={setScrollableElement}
          onScroll={onScroll}
        >
          <div
            className={`${scope}-header`}
            data-search-expanded={isSearchExpanded ? true : null}
          >
            <div className={`${scope}-logo`}>
              <button
                className={`${scope}-logoLink`}
                onClick={() => onSearch(null)}
              >
                <Logo className={`${scope}-logoImage`} />
              </button>
            </div>

            <Search
              search={search}
              onSearch={onSearch}
              className={`${scope}-search`}
              onToggleIsExpanded={setIsSearchExpanded}
              placeholder="Search conversations"
            />

            {!isMobile && navOptions?.length > 0 && (
              <ActionsDropdown
                className={`${scope}-actions`}
                collapsedIcon={!isMobile ? PlusIcon : undefined}
                aria-label="New conversation"
                data-conversation-new
                from={location.pathname}
                options={navOptions}
              />
            )}

            {!hasLigaOS && isMobile && (
              <LogoutButton className={`${scope}-logout`} />
            )}
          </div>

          <div className={`${scope}-gridTitle`} id="LatestChats">
            Latest chats
          </div>

          <div className={`${scope}-body`}>
            <AnimatePresence>
              {(isRefetching || isLoading) && (
                <AnimatedLoader className={`${scope}-reloading`} />
              )}
            </AnimatePresence>

            {!isRefetching && conversations?.length === 0 && !isLoading ? (
              <p className={`${scope}-noResults`}>No results found</p>
            ) : null}

            <InfiniteScroll
              className={`${scope}-list`}
              pageStart={0}
              loadMore={() => {
                if (!isFetchingNextPage) {
                  return fetchNextPage();
                }
              }}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollableElement}
              role="feed"
              aria-labelledby="LatestChats"
              aria-busy={
                isLoading || isRefetching || isFetchingNextPage ? true : null
              }
            >
              <AnimatePresence key="ConversationsAnimatePresence">
                {!isRefetching &&
                  conversations?.length > 0 &&
                  conversations.map((e) => {
                    const titleLength = e.title.length;
                    const modifierClasses = [
                      titleLength > 15 && titleLength <= 30
                        ? scope + "-item--medium"
                        : null,
                      titleLength > 30 ? scope + "-item--large" : null,
                    ].filter(Boolean);

                    const chatbotModel = chatbotModels?.find((model) => {
                      return model.id === e.chatbot_model_id;
                    });
                    const shouldHighlight = Boolean(
                      chatbotModel?.use_internal_data,
                    );

                    return (
                      <motion.div
                        className={`${scope}-item ${modifierClasses.join(" ")}`}
                        key={e.id}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          transition: { duration: 0.3, delay: 0.3 },
                        }}
                        exit={{ opacity: 0, transition: { duration: 0.3 } }}
                        data-should-highlight={shouldHighlight ? true : null}
                        role="article"
                      >
                        <Tile
                          data-conversation-id={e.id}
                          state={{ title: e.title, from: location.pathname }}
                          title={e.title}
                          to={`/chats/${e.id}`}
                        />
                      </motion.div>
                    );
                  })}
              </AnimatePresence>
            </InfiniteScroll>

            {isMobile && navOptions?.length > 0 && (
              <ActionsDropdown
                className={`${scope}-actions`}
                reverse={true}
                aria-label="New conversation"
                data-conversation-new
                from={location.pathname}
                options={navOptions}
              />
            )}

            {isFetchingNextPage && <AnimatedLoader />}
          </div>
        </div>
      </section>

      <AnimatePresence initial={false}>
        <Suspense key={location.pathname} fallback={<div>Loading...</div>}>
          {currentOutlet}
        </Suspense>
      </AnimatePresence>
    </>
  );
}

function AnimatedLoader({ ...props }) {
  return (
    <motion.div
      className={`${scope}-loading`}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: { duration: 0.2 },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.2 },
      }}
      {...props}
    >
      <Loader /> Loading ...
    </motion.div>
  );
}

export default Overview;
