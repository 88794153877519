import React, { useState } from "react";
import { authProvider } from "../utils/auth";
import "./LoginModal.scss";
import { useNavigate } from "react-router-dom";
import Logo from "../components/Logo";

const scope = "LoginModal";

function LoginModal() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    //Prevent page reload
    event.preventDefault();
    setErrorMessage("");

    const { username, password } = document.forms[0];
    try {
      setIsSubmitting(true);

      await authProvider.login(username.value, password.value);

      navigate("/");
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={scope}>
      <form
        onSubmit={handleSubmit}
        className={`${scope}-form`}
        aria-label="Login Form"
      >
        <Logo className={`${scope}-logo`} />

        <div className={`${scope}-input`}>
          <label htmlFor="LoginModalUsername">Username</label>
          <input type="text" name="username" required id="LoginModalUsername" />
        </div>

        <div className={`${scope}-input`}>
          <label htmlFor="LoginModalPassword">Password</label>
          <input
            type="password"
            name="password"
            id="LoginModalPassword"
            required
          />
        </div>

        <button type="submit" disabled={isSubmitting ? true : null}>
          Login
        </button>

        {errorMessage ? (
          <div className={`${scope}-error`} role="alert">
            {errorMessage}
          </div>
        ) : null}
      </form>
    </div>
  );
}

export default LoginModal;
