import { Factory } from "miragejs";
import { faker } from "@faker-js/faker";

export default Factory.extend({
  title() {
    return faker.lorem.words({ min: 2, max: 5 });
  },

  afterCreate(conversation, server) {
    if (!conversation.chatbot_model_id) {
      const chatbotModels = server.schema.all("chatbot-model").models;
      conversation.update({
        chatbot_model_id: faker.helpers.arrayElement(chatbotModels).id,
      });
    }
  },
});
