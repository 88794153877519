import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { makeServer } from "./mirage";
import { Response } from "miragejs";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import Login from "./pages/LoginModal";
import Overview, {
  initialDataLoader as conversationsLoader,
} from "./pages/Overview";
import ChatPage, { newRecord as newChatLoader } from "./pages/ChatPage";
import { authProvider } from "./utils/auth";
import { shouldEnableMirage } from "./mirage/utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PromptsNewPage from "./pages/Prompts/NewPage";
import PromptsIndex from "./pages/Prompts/Index";
import PromptsEditPage, {
  loader as predefinedTemplateLoader,
} from "./pages/Prompts/EditPage";
import ChatStarter, {
  loader as chatStarterLoader,
} from "./pages/Prompts/ChatStarter";

if (shouldEnableMirage()) {
  window.mirage = {
    server: makeServer({ environment: "development" }),
    Response,
  };
}

const router = createHashRouter([
  {
    id: "root",
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        element: <App />,
        errorElement: <ErrorPage />,
        loader: authProvider.load,
        children: [
          {
            path: "/",
            index: true,
            element: <Navigate replace to="/chats" />,
          },
          {
            path: "/chats",
            element: <Overview />,
            loader: conversationsLoader,
            shouldRevalidate(arg) {
              if (isTransitionFromNewToExisting(arg)) {
                // invalidate conversations list when new conversation is created
                return true;
              }

              return arg.defaultShouldRevalidate;
            },
            children: [
              {
                path: "/chats/:id",
                element: <ChatPage />,
                errorElement: <ErrorPage />,
                shouldRevalidate(arg) {
                  if (isTransitionFromNewToExisting(arg)) {
                    // do not load conversation twice when coming from the new conversation form
                    return false;
                  }

                  return arg.defaultShouldRevalidate;
                },
              },
              {
                path: "/chats/new",
                element: <ChatPage />,
                loader: newChatLoader,
              },
            ],
          },
          {
            path: "/prompts",
            element: <PromptsIndex />,
            children: [
              {
                path: "/prompts/new",
                element: <PromptsNewPage />,
              },
              {
                path: "/prompts/:id",
                element: <ChatStarter />,
                loader: chatStarterLoader,
                shouldRevalidate() {
                  // invalidate chat starter when navigating to it
                  // TODO: this should be done only when save happened on the edit page
                  return true;
                },
                children: [
                  {
                    path: "/prompts/:id/edit",
                    element: <PromptsEditPage />,
                    loader: predefinedTemplateLoader,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]);

function isTransitionFromNewToExisting(transition) {
  return (
    transition.currentUrl.pathname === "/chats/new" && transition.nextParams?.id
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
