export default class ApplicationIdentityManager {
  predefinedIdentifiers = [];

  constructor() {
    this.ids = new Set();
    this.nextId = 1;
  }

  /**
   * Returns an unique identifier.
   *
   * @method fetch
   * @param {Object} data Records attributes hash
   * @return {String} Unique identifier
   * @public
   */
  fetch() {
    const uuid = formatId(this.nextId);
    this.nextId++;
    this.ids.add(uuid);
    return uuid;
  }

  /**
   * Register an identifier.
   * Must throw if identifier is already used.
   *
   * @method set
   * @param {String|Number} id
   * @public
   */
  set(id) {
    if (this.ids.has(id)) {
      throw new Error(`ID ${id} is in use.`);
    }

    this.ids.add(id);

    if (isNumber(id) && +id >= this.nextId) {
      this.nextId = +id + 1;
    }
  }

  /**
   * Reset identity manager.
   *
   * @method reset
   * @public
   */
  reset() {
    this.ids.clear();
  }
}

/**
 * Format a number to look like uuid.
 *
 * Given the input "14" it returns it as "00000000-0000-0000-0000-000000000014".
 *
 * @param {string|number} id
 * @returns string
 */
function formatId(id) {
  const string = `${id}`.padStart(32, "0");
  return [
    string.substring(0, 8),
    string.substring(8, 12),
    string.substring(12, 16),
    string.substring(16, 20),
    string.substring(20),
  ].join("-");
}

function isNumber(n) {
  return `${+n}` === `${n}`;
}
