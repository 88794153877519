import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import QuestionRow from "./Chat/QuestionRow";
import { authRequest, baseUrl } from "../utils/NetworkUtils";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { ReactTyped } from "react-typed";
import { v4 } from "uuid";
import { InlineEditable } from "./Chat/InlineEditable";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMediaQuery } from "@react-hook/media-query";
import Loader from "./Loader";
import QuestionForm from "./Chat/QuestionForm";
import { createEventSource } from "../utils/event-source";
import { authProvider } from "../utils/auth";

import "./Chat.scss";

const scope = `Chat`;
const questionsUrl = `${baseUrl}/api/questions/`;
const pageSize = 20;
const firstPage = 1;

function loadConversation(conversationId) {
  return authRequest(`${baseUrl}/api/conversations/${conversationId}/`);
}

function Chat({ conversationId }) {
  // workaround the React strict mode. So we don't trigger initial data requests twice
  const initialLoadingRanFor = useRef(null);
  const queryClient = useQueryClient();
  const [bodyElement, setBodyElement] = useState(null);
  const [dialogElement, setDialogElement] = useState(null);
  const [eventSource, setEventSource] = useState(null);
  const isMobile = useMediaQuery("only screen and (max-width: 768px)");
  const [qps] = useSearchParams();
  const [predefinedPromptId] = useState(qps.get("pt") || "");
  const location = useLocation();
  const [title, setTitle] = useState(location.state?.title ?? "");

  const id = conversationId;

  const [newConversation, setNewConversation] = useState(
    location.state?.newConversation,
  );

  const [chatModelId, setChatModelId] = useState(
    newConversation?.chatModelId ?? location.state?.modelVersion,
  );

  const { data: chatbotModels } = useQuery({
    queryKey: ["chatbot-models"],
    queryFn: async () => {
      const res = await authRequest(`${baseUrl}/api/chatbot-models/`);

      return res.results;
    },
  });

  const { data: predefinedPrompt } = useQuery({
    queryKey: ["predefined-prompts", predefinedPromptId],
    queryFn: async () => {
      if (predefinedPromptId) {
        return authRequest(
          `${baseUrl}/api/predefined-prompts/${predefinedPromptId}/`,
        );
      }
    },
  });

  const currentChatModel = useMemo(() => {
    return chatbotModels?.find((model) => model.id === chatModelId);
  }, [chatbotModels, chatModelId]);

  // reset auxiliary state after the first render
  // so that animation doesn't happen on page reload
  const { state } = window.history;
  if (state?.usr?.newConversation) {
    const newState = {
      ...state,

      usr: {
        ...state.usr,
        // reset animation state from the new conversation screen
        newConversation: null,
      },
    };

    window.history.replaceState(newState, "", window.location.toString());
  }

  const intermediateData = [newConversation?.question]
    .filter(Boolean)
    .map((text) => ({ text, isNew: true }));

  const [data, setData] = useState(intermediateData);

  const [activeAnswer, setActiveAnswer] = useState(null);

  const [hasMore, setHasMore] = useState(Boolean(id));
  const [page, setPage] = useState(firstPage);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [question, setQuestion] = useState("");
  const navigate = useNavigate();

  const shouldDisable = Boolean(
    isSubmitting || newConversation || activeAnswer,
  );

  const fromNew = useMemo(() => {
    if (data.length !== 1) {
      return false;
    }

    if (newConversation) {
      return true;
    }

    const [question] = data;

    return !question.answers?.length && question.text && question.isNew;
  }, [data, newConversation]);

  const scrollToBottom = useCallback(() => {
    if (!dialogElement) return;

    dialogElement.scrollTo({
      top: dialogElement.scrollHeight,
      behavior: "smooth",
    });
  }, [dialogElement]);

  const submitTitle = async (title) => {
    return authRequest(`${baseUrl}/api/conversations/${id}/`, {
      method: "PUT",
      body: JSON.stringify({ id, title }),
    }).then((conversation) => {
      setTitle(conversation.title);

      queryClient.setQueryData(["conversations"], (data) => ({
        pages: data.pages.map((page) => {
          return page.map((item) => {
            if (item.id === conversation.id) {
              return {
                ...item,
                title: conversation.title,
              };
            }

            return item;
          });
        }),
        pageParams: data.pageParams,
      }));
    });
  };

  const loadQuestion = useCallback(
    async (id) => {
      const questionResponse = await authRequest(
        baseUrl + `/api/questions/${id}/`,
        {
          method: "GET",
        },
      );

      setData(
        data.map((e) =>
          e.id === id || (!e.id && e.isNew) ? questionResponse : e,
        ),
      );
    },
    [setData, data],
  );

  const reloadActiveAnswer = useCallback(async () => {
    const answer = await authRequest(
      `${baseUrl}/api/answers/${activeAnswer.id}/`,
    );

    await loadQuestion(answer.question_id);
    setActiveAnswer(null);
  }, [activeAnswer, loadQuestion, setActiveAnswer]);

  const finishAnswerChunks = useCallback(async () => {
    eventSource.close();
    setEventSource(null);
  }, [eventSource]);

  const handleChunk = useCallback(
    (event) => {
      if (!event.data) {
        // for some reason sse.js emits the first message empty
        return;
      }

      const { token } = JSON.parse(event.data);

      setActiveAnswer((prev) => {
        return {
          ...prev,

          text: [prev.text ?? "", token].join(""),
        };
      });
    },
    [setActiveAnswer],
  );

  // do nothing for now. On the streaming close event, we'll reload the answer
  const handleError = () => {};

  const listenToAnswer = useCallback(
    async (id) => {
      if (eventSource) {
        throw new Error("Event source is already open");
      }

      const token = await authProvider.loadToken();
      const newEventSource = createEventSource(
        `${baseUrl}/api/answers/${id}/events/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setEventSource(newEventSource);

      setActiveAnswer({
        id: id,
        chunk: null,
        chunks: [],
      });
    },
    [setEventSource, setActiveAnswer, eventSource],
  );

  const regenerateAnswer = useCallback(
    async (id) => {
      const newAnswerId = v4();

      listenToAnswer(newAnswerId);

      const question = await authRequest(
        baseUrl + `/api/questions/${id}/regenerate/`,
        {
          method: "POST",

          body: JSON.stringify({
            answer_id: newAnswerId,
          }),
        },
      );

      setData(data.map((e) => (e.id === id ? question : e)));
    },
    [listenToAnswer, setData, data],
  );

  const load = useCallback(async () => {
    const searchString = new URLSearchParams({
      page,
      pageSize,
    });

    const data = await authRequest(
      `${questionsUrl}?conversation_id=${id}&${searchString}`,
    );

    const { results } = data;
    const isPendingAnswer = (answer) => answer.is_pending;
    const pendingQuestion = results.find((question) => {
      return question.answers?.some(isPendingAnswer);
    });

    if (pendingQuestion) {
      const pendingAnswer = pendingQuestion.answers.find(isPendingAnswer);
      if (pendingAnswer) {
        listenToAnswer(pendingAnswer.id);
      }
    }
    setHasMore(!(results.length < pageSize));

    return await results.reverse();
  }, [page, id, listenToAnswer, setHasMore]);

  const handleChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const loadMore = useCallback(() => {
    if (page === firstPage) {
      // we load initial data in useEffect, so let's avoid concurrent requests
      return;
    }

    return load().then((messages) => {
      if (page === firstPage) {
        setData(messages);
      } else {
        setData([...messages, ...data]);
      }

      setPage(page + 1);
    });
  }, [data, page, load]);

  const postQuestion = useCallback(
    async (text, conversationId) => {
      const newAnswerId = v4();

      if (id) {
        // listen to a new answer if it's an existing conversation
        // otherwise, it it's a new conversation we'll listen to the answer
        // later when the redirect happens
        listenToAnswer(newAnswerId);
      }

      try {
        const response = await authRequest(questionsUrl, {
          method: "POST",
          body: JSON.stringify({
            question: text,
            conversation_id: conversationId,
            answer_id: newAnswerId,
          }),
        });
        const { id, answers, related_files } = response;
        setData((prev) => {
          return prev.concat({
            id,
            text,
            answers,
            related_files,
            isNew: true,
          });
        });
      } finally {
        setData((prev) => {
          return prev.filter((e) => !!e.answers);
        });
      }
    },
    [listenToAnswer, setData, id],
  );

  const submitQuestion = async function (text) {
    setData(data.concat({ text, isNew: true }));
    setIsSubmitting(true);

    try {
      if (!id) {
        await addConversation(text);
      } else {
        await postQuestion(text, id);
      }

      setQuestion("");
    } finally {
      setIsSubmitting(false);
    }
  };

  const addConversation = async (text) => {
    const chatbot_model_id =
      chatModelId ?? (chatbotModels.length > 0 && chatbotModels[0].id);

    if (!chatbot_model_id) {
      setIsSubmitting(false);
      throw new Error("No chatbot model selected");
    }

    const conversation = await authRequest(baseUrl + "/api/conversations/", {
      method: "POST",
      body: JSON.stringify({ question: text, chatbot_model_id }),
    });

    await postQuestion(text, conversation.id);
    setNewConversation(conversation);

    queryClient.setQueryData(["conversations"], () => {
      return {
        pages: [],
        pageParams: [],
      };
    });

    return navigate(`/chats/${conversation.id}`, {
      state: {
        newConversation: { ...conversation, question: text, chatModelId },
        from: "new-chat",
      },
    });
  };

  const changeActiveAnswer = async (question, answerId) => {
    await authRequest(`${baseUrl}/api/answers/${answerId}/best-answer/`, {
      method: "PATCH",
    });

    setData((prev) => {
      return prev.map((e) => {
        if (e.id === question.id) {
          return {
            ...question,
            answers: question.answers.map((a) => {
              return { ...a, is_best: a.id === answerId };
            }),
          };
        }

        return e;
      });
    });
  };

  const changeLike = async (question, answerId, isLiked) => {
    await authRequest(`${baseUrl}/api/answers/${answerId}/like/`, {
      method: "PATCH",
      body: JSON.stringify({ is_liked: isLiked }),
    });

    setData((prev) => {
      return prev.map((e) => {
        if (e.id === question.id) {
          return {
            ...question,
            answers: question.answers.map((a) => {
              return {
                ...a,
                is_liked: a.id === answerId ? isLiked : a.is_liked,
              };
            }),
          };
        }

        return e;
      });
    });
  };

  useEffect(() => {
    const isStreamingInProgress =
      activeAnswer && (activeAnswer.chunk || activeAnswer?.chunks?.length);

    if (activeAnswer && !isStreamingInProgress && !eventSource) {
      reloadActiveAnswer();
      setNewConversation(null);
    }
  }, [activeAnswer, eventSource, reloadActiveAnswer, question, navigate]);

  useEffect(() => {
    if (!eventSource) {
      return;
    }

    eventSource.addEventListener("message", handleChunk);
    eventSource.addEventListener("error", handleError);
    eventSource.addEventListener("close", finishAnswerChunks);

    return () => {
      eventSource.removeEventListener("message", handleChunk);
      eventSource.removeEventListener("error", handleError);
      eventSource.removeEventListener("close", finishAnswerChunks);
    };
  }, [eventSource, handleChunk, finishAnswerChunks]);

  useEffect(() => {
    if (!bodyElement) return;

    const resizeObserver = new ResizeObserver(() => {
      const enforceScrollThreshold =
        dialogElement.scrollHeight -
          dialogElement.scrollTop -
          dialogElement.clientHeight <
        300;

      if (enforceScrollThreshold) {
        scrollToBottom();
      }
    });
    resizeObserver.observe(bodyElement);
    resizeObserver.observe(dialogElement);

    return () => {
      resizeObserver.disconnect(); // clean up
    };
  }, [scrollToBottom, bodyElement, dialogElement]);

  useEffect(() => {
    if (initialLoadingRanFor.current === id) {
      return;
    }
    initialLoadingRanFor.current = id;

    setPage(firstPage);

    if (!id) {
      setHasMore(false);
      setData([]);

      return;
    }

    setHasMore(true);

    loadConversation(id).then((conversation) => {
      setTitle(conversation.title);
      setChatModelId(conversation.chatbot_model_id);
    });

    load().then((messages) => {
      setData(messages);
      setPage(page + 1);

      const pendingAnswer = messages
        .find((question) => {
          return question.answers?.some((answer) => answer.is_pending);
        })
        ?.answers?.find((answer) => answer.is_pending);

      if (pendingAnswer) {
        listenToAnswer(pendingAnswer.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className={scope} data-is-new={id || newConversation ? null : true}>
      <div className={`${scope}-header`}>
        <h2 className={`${scope}-title`}>
          {newConversation ? (
            <div className={`${scope}-title--typed`}>
              <ReactTyped
                strings={[newConversation.title]}
                typeSpeed={100}
                showCursor={false}
              />
              &nbsp;
            </div>
          ) : isMobile ? (
            title ?? "Title"
          ) : (
            <InlineEditable
              key={title}
              value={title}
              placeholder={"Title"}
              maxLength={60}
              save={title ? submitTitle : null}
            />
          )}
        </h2>
      </div>

      <div className={`${scope}-dialog`} ref={setDialogElement}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          isReverse={true}
          loader={
            !fromNew ? (
              <div className={`${scope}-loading`}>
                <Loader size={30} /> Loading...
              </div>
            ) : null
          }
          useWindow={false}
        >
          <div ref={setBodyElement}>
            {data.map((e, index) => (
              <QuestionRow
                key={e.id}
                model={e}
                activeAnswer={data.length === index + 1 ? activeAnswer : null}
                shouldAnimateAnswer={
                  e.isNew || Boolean(location.state?.newConversation)
                }
                shouldAnimateQuestion={
                  !Boolean(location.state?.newConversation) &&
                  e.isNew &&
                  !e.title &&
                  !e.answers?.length &&
                  !e.related_files
                }
                regenerate={index === data.length - 1 ? regenerateAnswer : null}
                isLast={index === data.length - 1}
                setActiveAnswer={(answerId) => changeActiveAnswer(e, answerId)}
                changeLike={(answerId, isLiked) =>
                  changeLike(e, answerId, isLiked)
                }
              />
            ))}
          </div>
        </InfiniteScroll>
      </div>

      <QuestionForm
        className={`${scope}-inputBlock`}
        onSubmit={submitQuestion}
        onChange={handleChangeQuestion}
        question={predefinedPrompt?.instructions ?? question ?? ""}
        disable={shouldDisable}
        currentChatModel={currentChatModel}
      />

      <p className={`${scope}-useWithCaution`}>
        LigaAgent can make mistakes. Please always validate important
        information.
      </p>
    </div>
  );
}

export default Chat;
