import { Response } from "miragejs";
import { routeTimeout } from "../utils";
import { streamAnswer } from "./answers";

export default function routes(server) {
  server.get(
    "/questions",
    (schema, request) => {
      const pageSize = Number(request.queryParams.pageSize ?? 10);
      const page = Number(request.queryParams.page ?? 1);
      const conversationId = request.queryParams.conversation_id;
      if (!conversationId) {
        return new Response(
          400,
          {},
          {
            detail: "conversation_id is required",
          },
        );
      }

      const filtered = schema
        .where("question", { conversation_id: conversationId })
        // reverse
        .sort(() => -1);

      const fromIndex = (page - 1) * pageSize;
      return filtered.slice(fromIndex, fromIndex + pageSize);
    },
    { timing: 0 },
  );

  server.post(
    "/questions",
    (schema, request) => {
      const payload = JSON.parse(request.requestBody);
      const newAnswerId = payload.answer_id;

      const question = server.create("question", {
        ...payload,
        text: payload.question,
        answers: [],
      });

      const answer = server.create("answer", {
        id: newAnswerId,
        questionId: question.id,
        is_pending: true,
        text: "",
      });

      streamAnswer(answer, payload.question);

      return question;
    },
    routeTimeout(300),
  );

  server.get(
    "/questions/:id",
    (schema, request) => {
      return schema.find("question", request.params.id);
    },
    { timing: 0 },
  );

  server.post(
    "/questions/:id/regenerate",
    (schema, request) => {
      const { id } = request.params;
      const { answer_id } = JSON.parse(request.requestBody);

      const answer = server.create("answer", {
        id: answer_id,
        questionId: id,
        is_pending: true,
        text: "",
      });

      streamAnswer(answer);

      return schema.find("question", id);
    },
    routeTimeout(2000),
  );
}
