import React, { useMemo, useState } from "react";
import { authProvider } from "../../utils/auth";
import DynamicHeightDiv from "./DynamicHeightDiv";
import UserPicture from "../UserPicture";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as RefreshIcon } from "../../icons/refresh.svg";
import { ReactComponent as LikeIcon } from "../../icons/like.svg";
import { ReactComponent as LikePressedIcon } from "../../icons/like-filled.svg";
import { ReactComponent as ArrowIcon } from "../../icons/arrow.svg";
import { ReactComponent as ExclamationIcon } from "../../icons/exclamation.svg";
import { marked } from "marked";

import "./QuestionRow.scss";

const scope = "QuestionRow";

const SLIDE_DURATION = 0.6;

function QuestionRow({
  model,
  shouldAnimateAnswer,
  shouldAnimateQuestion,
  activeAnswer,
  ref,
  regenerate,
  setActiveAnswer,
  changeLike,
  isLast,
}) {
  const answerIndex = useMemo(() => {
    const bestAnswerIndex = model.answers?.findIndex((a) => a.is_best);

    const lastAnswerIndex = model.answers ? model.answers?.length - 1 : 0;

    return bestAnswerIndex > -1 ? bestAnswerIndex : lastAnswerIndex;
  }, [model]);

  const answer = useMemo(() => {
    if (activeAnswer && (activeAnswer.chunk || activeAnswer.text)) {
      return { is_pending: true, ...activeAnswer };
    }

    if (answerIndex !== -1) {
      const persistentAnser = model.answers?.[answerIndex];
      if (!persistentAnser?.is_pending) {
        return persistentAnser;
      }
    }

    return null;
  }, [activeAnswer, answerIndex, model.answers]);

  const isTyping = useMemo(() => {
    return answer?.is_pending;
  }, [answer]);

  const answersCount = model.answers?.filter((a) => !a.is_pending).length || 0;

  const [shouldType, setShouldType] = useState(shouldAnimateAnswer);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const prevAnswer = () => {
    const { id } = model.answers?.[answerIndex - 1];
    setActiveAnswer(id);
  };

  const nextAnswer = () => {
    const { id } = model.answers?.[answerIndex + 1];
    setActiveAnswer(id);
  };

  const like = (val) => {
    const answer = model.answers?.[answerIndex];
    if (answer) {
      if (answer.is_liked === val) {
        changeLike(answer.id, null);
      } else {
        changeLike(answer.id, val);
      }
    }
  };

  const _regenerate = () => {
    setShouldType(true);
    setIsRegenerating(true);

    return regenerate(model.id).finally(() => {
      setIsRegenerating(false);
    });
  };

  return (
    <div
      className={scope}
      ref={ref}
      data-is-typing={isTyping ? true : null}
      data-is-regenerating={isRegenerating ? true : null}
      data-is-last={isLast ? true : null}
      role="listitem"
    >
      {model.text && (
        <div className={`${scope}-question`}>
          <Question text={model.text} shouldAnimate={shouldAnimateQuestion} />
        </div>
      )}
      {answer ? (
        <div className={`${scope}-answer`} data-is-liked={answer.is_liked}>
          <Answer
            answer={answer}
            shouldAnimate={shouldAnimateAnswer}
            regenerate={isLast ? _regenerate : null}
            isRegenerating={isRegenerating}
            shouldType={shouldType}
            activeAnswer={activeAnswer}
            answersCount={answersCount}
            answerIndex={answerIndex}
            prevAnswer={prevAnswer}
            nextAnswer={nextAnswer}
            like={like}
          />
        </div>
      ) : null}
    </div>
  );
}

function Question({ text, shouldAnimate }) {
  return (
    <motion.div
      className={`${scope}-line`}
      initial={shouldAnimate ? { opacity: 0, y: 80, height: 0 } : null}
      animate={shouldAnimate ? { opacity: 1, y: 0, height: "auto" } : null}
      transition={{
        duration: SLIDE_DURATION,
      }}
      aria-label="Question"
    >
      <div className={`${scope}-questionBody`}>
        <UserPicture
          className={`${scope}-userIcon`}
          user={authProvider.user}
          aria-label="User icon"
        />

        <div className={`${scope}-message`} data-testid="question-text">
          {text}
        </div>

        <div className={`${scope}-fakeIcon`}></div>
      </div>
    </motion.div>
  );
}

function Answer({
  answer,
  shouldAnimate,
  regenerate,
  isRegenerating,
  shouldType,
  activeAnswer,
  answersCount,
  answerIndex,
  prevAnswer,
  nextAnswer,
  like,
}) {
  // in case if visit chat with a pending answer, we have to render
  const persistedText =
    answer && !answer.is_pending && answer.text ? answer.text : "";
  const typedText = activeAnswer?.text ? activeAnswer.text : "";
  const staticText = answer.error_message || `${persistedText}${typedText}`;

  const [sourcesExpanded, setSourcesExpanded] = useState(false);

  const toggleShowSources = () => {
    setSourcesExpanded(!sourcesExpanded);
  };

  return (
    <motion.div
      key={answer.id}
      className={`${scope}-line`}
      initial={shouldAnimate ? { opacity: 0, y: 80, height: 0 } : null}
      animate={shouldAnimate ? { opacity: 1, y: 0 } : null}
      transition={{
        duration: SLIDE_DURATION,
      }}
      aria-label="Answer"
      data-has-error={answer.error_message ? true : null}
    >
      <div className={`${scope}-answerBody`}>
        <div className={`${scope}-fakeIcon`}>
          {regenerate ? (
            <button
              className={`${scope}-regenerate`}
              onClick={regenerate}
              title="Generate new answer"
              disabled={isRegenerating ? true : null}
            >
              <RefreshIcon />
            </button>
          ) : null}
        </div>

        <Message
          className={`${scope}-message`}
          data-testid="answer-text"
          key={answer.id}
          text={staticText}
          shouldType={
            !answer.error_message &&
            (Boolean(activeAnswer) || shouldAnimate || shouldType)
          }
          type={answer.error_message ? "error" : "default"}
        />

        <div role="img" aria-label="User icon" className={`${scope}-userIcon`}>
          AI
        </div>
      </div>

      <div
        className={`${scope}-answerActions`}
        data-disabled={isRegenerating ? true : null}
      >
        <div className={`${scope}-answerActionsSpacer`}></div>

        <div className={`${scope}-answerActionsBody`}>
          <div className={`${scope}-answerActionsLine`}>
            {regenerate ? (
              <button
                className={`${scope}-regenerate`}
                onClick={regenerate}
                title="Generate new answer"
                disabled={isRegenerating ? true : null}
              >
                <RefreshIcon />
              </button>
            ) : null}

            {answersCount > 1 ? (
              <div className={`${scope}-answerPager`} aria-label={"Pagination"}>
                <button
                  className={`${scope}-answerPagerPrev`}
                  onClick={prevAnswer}
                  disabled={answerIndex > 0 ? null : true}
                  aria-label="Previous answer"
                >
                  {"<"}
                </button>
                <span className={`${scope}-answerCurrent`}>
                  {answerIndex + 1}
                </span>
                /
                <span className={`${scope}-answerActionsCount`}>
                  {answersCount}
                </span>
                <button
                  className={`${scope}-answerPagerNext`}
                  onClick={nextAnswer}
                  disabled={answerIndex < answersCount - 1 ? null : true}
                  aria-label="Next answer"
                >
                  {">"}
                </button>
              </div>
            ) : null}

            <button
              className={`${scope}-likeButton`}
              onClick={() => like(true)}
            >
              {answer.is_liked ? <LikePressedIcon /> : <LikeIcon />}
            </button>

            <button
              className={`${scope}-unlikeButton`}
              onClick={() => like(false)}
            >
              {answer.is_liked === false ? <LikePressedIcon /> : <LikeIcon />}
            </button>
          </div>

          {answer.related_files?.length ? (
            <button
              className={`${scope}-sourcesToggle`}
              onClick={toggleShowSources}
              aria-label="Sources"
              id={`${scope}Sources-${answer.id}`}
            >
              {sourcesExpanded ? "Hide sources" : "Show sources"}
            </button>
          ) : null}
        </div>

        <div className={`${scope}-answerActionsSpacer`}></div>
      </div>

      <AnimatePresence>
        {sourcesExpanded ? (
          <motion.div
            className={`${scope}-answerActions`}
            data-disabled={isRegenerating ? true : null}
            initial={{ opacity: 0, maxHeight: 0 }}
            animate={{ opacity: 1, maxHeight: "30vh" }}
            exit={{ opacity: 0, maxHeight: 0 }}
          >
            <div className={`${scope}-answerActionsSpacer`}></div>
            <ul
              className={`${scope}-sources`}
              aria-labelledby={`${scope}Sources-${answer.id}`}
            >
              {answer.related_files?.map((source) => {
                const { id, name, page_numbers, url } = source;

                const hasPageNumbers = page_numbers?.length > 0;
                const plural = page_numbers?.length > 1 ? "s" : "";
                const filenameSuffix = hasPageNumbers
                  ? ` (page${plural ? "s" : ""} ${page_numbers.join(", ")})`
                  : "";

                return (
                  <li className={`${scope}-sourceItem`}>
                    <a
                      className={`${scope}-sourceLink`}
                      key={id}
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className={`${scope}-sourceLinkFilename`}>
                        {name}
                        {filenameSuffix}
                      </div>

                      <ArrowIcon className={`${scope}-sourceLinkIcon`} />
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className={`${scope}-fakeIcon`}></div>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </motion.div>
  );
}

const TextNode = ({ className = "", dataTestId, text, ...props }) => {
  const html = marked(text);

  return (
    <div
      className={`${className} markdown-body`.trim()}
      dangerouslySetInnerHTML={{ __html: html }}
      {...props}
    />
  );
};

function Message({ text, shouldType, className, type, ...props }) {
  if (shouldType) {
    return (
      <div className={className}>
        <DynamicHeightDiv transition={{ duration: 0.3 }}>
          <TextNode text={text} {...props} />
        </DynamicHeightDiv>
      </div>
    );
  }

  return (
    <div className={className}>
      {type === "error" ? (
        <div className={`${scope}-messageErrorIcon`} aria-label="Error Icon">
          <ExclamationIcon />
        </div>
      ) : null}
      <TextNode text={text} {...props} />
    </div>
  );
}

export default QuestionRow;
