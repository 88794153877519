export function shouldEnableMirage() {
  return ["on", "1", "true"].includes(localStorage.getItem("offline"));
}

export function routeTimeout(ms) {
  const networkTimeoutMultiplier = Number(
    localStorage.getItem("offline.networkTimeoutMultiplier") ?? 1,
  );

  return {
    timing: ms * networkTimeoutMultiplier,
  };
}
