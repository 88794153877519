import { BounceLoader } from "react-spinners";

function Loader({ loading = true, size = 30, className, ...props }) {
  return (
    <BounceLoader
      className={className}
      color={"var(--accentColor)"}
      loading={loading}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
      {...props}
    />
  );
}

export default Loader;
