import { redirect } from "react-router-dom";
import { authProvider } from "./auth";
import { toast } from "react-toastify";

export const baseUrl =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:8000";

export const authRequest = async (url, options) => {
  const token = await authProvider.loadToken();
  if (!token) {
    throw new Error("Token must be lodeded before making authorized requests");
  }

  const response = await fetch(url, {
    method: "GET",
    ...options,
    headers: {
      ...options?.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status < 200 || response.status >= 300) {
    handleFetchErrors(response);
  }

  return response.status === 204 ? null : await response.json();
};

const handleFetchErrors = async (response) => {
  const { status, statusText } = response;

  if (status === 401) {
    authProvider.logout();
    redirect("/login");
  } else if (status >= 400) {
    const syncResponseJSON = await response.json();
    const errorMessage = syncResponseJSON.detail ?? statusText;
    toast.error(errorMessage, {
      toastId: status,
      theme: "dark",
      position: "top-center",
    });

    throw statusText;
  } else if (status !== 200) {
    console.error(status, statusText);
  }

  return response;
};
