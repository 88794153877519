import { ReactComponent as LIGAagentLogo } from "../icons/logo.svg";
import { ReactComponent as SyncWiseLogo } from "../icons/syncwise-logo.svg";

export default function Logo({ ...props }) {
  return (
    <div>
      {process.env.REACT_APP_PRODUCT_NAME === "LIGAagent" ? (
        <LIGAagentLogo {...props} />
      ) : (
        <SyncWiseLogo {...props} />
      )}
    </div>
  );
}
