import { routeTimeout } from "../utils";

export default function routes(server) {
  server.get(
    "/users/:id",
    (schema, request) => {
      const id = request.params.id;

      return schema.find("user", id);
    },
    routeTimeout(100),
  );

  server.get(
    "/users/",
    (schema, request) => {
      const ids = request.queryParams?.user_ids
        .split(",")
        .map((id) => id.trim());

      const users = ids.length
        ? schema.users.where((u) => ids.includes(u.id))
        : schema.users.all();

      return users;
    },
    routeTimeout(100),
  );
}
