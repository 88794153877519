import "./MultiChoice.scss";
import * as React from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from "@floating-ui/react";

/*
 * groups = [
 *  {
 *    name: "group1",
 *    options: [
 *      { label: "label 1", value: "value1" },
 *      { label: "label 2", value: "value2" },
 *    }
 *  }
 * ]
 *
 * values = {
 *   group1: ["value1", "value2"]
 * }
 */
export default function MultiChoice({
  groups,
  values,
  onChange,
  trigger = "Select...",
  form,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "bottom-end",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = React.useRef([]);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
    click,
  ]);

  return (
    <>
      <div
        className={`MultiChoice`}
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
      >
        {trigger}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              className="MultiChoice-dropdown"
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
              }}
              {...getFloatingProps()}
            >
              {groups.map((group) =>
                group.options.map((option, i) => (
                  <label
                    className="MultiChoice-row"
                    key={`${group.name}-${option.value}`}
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                  >
                    <input
                      type="checkbox"
                      name={group.name}
                      value={option.value}
                      checked={values[group.name]?.includes(option.value)}
                      onChange={onChange}
                      form={form}
                    />
                    {option.label}
                  </label>
                )),
              )}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}
