import { Serializer } from "miragejs";
import { pluralize, underscore, singularize } from "inflection";

export default class AppSerializer extends Serializer {
  root = false;

  normalize(payload) {
    return Serializer.prototype.normalize.call(this, {
      [this.type]: payload,
    });
  }

  serialize(response, ...args) {
    const json = Serializer.prototype.serialize.apply(this, [
      response,
      ...args,
    ]);

    if (this.isCollection(response)) {
      return {
        results: json,
      };
    }

    return json;
  }

  keyForModel(type) {
    return underscore(type);
  }

  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(type) {
    return pluralize(underscore(type));
  }

  keyForEmbeddedRelationship(attributeName) {
    return underscore(attributeName);
  }

  keyForRelationshipIds(type) {
    return `${underscore(singularize(type))}_ids`;
  }

  keyForForeignKey(relationshipName) {
    return `${underscore(relationshipName)}_id`;
  }

  keyForPolymorphicForeignKeyId(relationshipName) {
    return `${underscore(relationshipName)}_id`;
  }

  keyForPolymorphicForeignKeyType(relationshipName) {
    return `${underscore(relationshipName)}_type`;
  }
}
