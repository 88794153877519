import { Factory } from "miragejs";
import { faker } from "@faker-js/faker";

export default Factory.extend({
  title() {
    return faker.lorem.word();
  },

  desription() {
    return faker.lorem.paragraph();
  },
});
