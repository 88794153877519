import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import LigaOS from "ln-ember-toolkit/addon/utils/liga-os";
import { ToastContainer } from "react-toastify";
import UserAgreement from "./components/UserAgreement";
import { authProvider } from "./utils/auth";
import navItems, { shouldHide as shouldHideAppNav } from "./utils/navigation";
import { isTouchEnabled } from "./utils/device";
import LogoutButton from "./components/LogoutButton";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

const scope = "App";

const defaultIsSidebarActive = isTouchEnabled();

const hasLigaOS = LigaOS.isLigaOsPresent();

function App() {
  const location = useLocation();
  const [sidebarMouseTimeout, setSidebarMouseTimeout] = useState(null);
  const [isSidebarActive, setIsSidebarActive] = useState(
    defaultIsSidebarActive,
  );

  const [agreementAccepted, setAgreementAccepted] = useState(
    authProvider.user.agreement_accepted ?? false,
  );

  const handleSidebarMouseOver = () => {
    clearTimeout(sidebarMouseTimeout);

    setSidebarMouseTimeout(
      setTimeout(() => {
        setIsSidebarActive(true);
      }, 500),
    );
  };

  const handleSidebarMouseLeave = () => {
    clearTimeout(sidebarMouseTimeout);
    setIsSidebarActive(defaultIsSidebarActive);
  };

  useEffect(() => {
    LigaOS.setUrl(window.location.href);
  }, [location.pathname, location.hash, location.search]);

  return (
    <main className={scope} data-no-agreement={agreementAccepted ? null : true}>
      {!shouldHideAppNav && (
        <aside
          className={`${scope}-sidebar`}
          onMouseOver={handleSidebarMouseOver}
          onMouseLeave={handleSidebarMouseLeave}
          data-active={isSidebarActive ? true : null}
        >
          <nav className={`${scope}-nav`}>
            <ul className={`${scope}-navList`}>
              {navItems().map((item) => (
                <li key={item.label} className={`${scope}-navItem`}>
                  <NavLink
                    className={`${scope}-navLink`}
                    to={item.to}
                    end={false}
                  >
                    <item.icon className={`${scope}-navIcon`} />
                    <span className={`${scope}-navText`}>{item.label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          {!hasLigaOS && (
            <LogoutButton className={`${scope}-logout`}>
              <span className={`${scope}-navText`}>Log out</span>
            </LogoutButton>
          )}
        </aside>
      )}

      <div className={`${scope}-body`}>
        <Outlet />
      </div>

      {!agreementAccepted && (
        <div className={`${scope}-userAgreement`}>
          <UserAgreement
            className={`${scope}-userAgreementBody`}
            onAccept={() => setAgreementAccepted(true)}
          />
        </div>
      )}
      <ToastContainer />
    </main>
  );
}

export default App;
