import "./InlineParam.scss";

import { useState, forwardRef, useEffect } from "react";

const scope = `InlineParam`;

export default function InlineParam({
  name,
  value,
  className,
  onChange,
  inputId,
  ...props
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [ref, setRef] = useState(null);

  const isMultiline = value?.includes("\n");

  return (
    <span
      className={`${className ?? ""} ${scope}`}
      {...props}
      data-missing={!value || null}
      title={!value ? "Please fill in the value" : null}
      data-edit-mode={isEditMode ? true : null}
      data-multiline={isMultiline ? true : null}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setTimeout(() => {
          ref?.focus();
        }, 50);
      }}
      aria-label={name}
    >
      {/* Render a fake input which transparent to have an */}
      {/* easy way of submit validations for the required fields */}
      <input
        type="text"
        name={name}
        value={value}
        required
        id={inputId}
        tabIndex={-1} // prevent tabbing
        style={{
          opacity: 0,
          position: "absolute",
          inset: 0,
          zIndex: -1, // avoid to catch clicks
        }}
        autoComplete="off" // hide the browser autocomplete
        onFocus={() => {
          // delegate focus to the contenteditable element
          ref?.focus();
        }}
        onChange={() => {
          // Since we pass a value, React requires us to define one of onChange or readOnly
          // With the readOnly, we won't see validation messages.
          // So, let's have an empty onChange to appease React, and have native validation tooltips.
        }}
      />

      <span className={`${scope}-placeholder`}>{name}</span>

      <ContentEditable
        className={`${scope}-value`}
        value={value}
        onChange={onChange}
        onBlur={() => {
          setIsEditMode(false);
        }}
        onFocus={(e) => {
          setIsEditMode(true);

          // select all text on focus
          // https://stackoverflow.com/a/3806004I
          // setTimeout(function () {
          //   let sel, range;
          //   if (window.getSelection && document.createRange) {
          //     range = document.createRange();
          //     range.selectNodeContents(e.target);
          //     sel = window.getSelection();
          //     sel.removeAllRanges();
          //     sel.addRange(range);
          //   } else if (document.body.createTextRange) {
          //     range = document.body.createTextRange();
          //     range.moveToElementText(e.target);
          //     range.select();
          //   }
          // }, 1);
        }}
        noSync={isEditMode}
        onClick={() => {
          setIsEditMode(true);
        }}
        ref={setRef}
        data-name={name}
      />
    </span>
  );
}

const ContentEditable = forwardRef(
  ({ value: valueArg, noSync, onChange, ...rest }, ref) => {
    const [value, setValue] = useState(valueArg);

    useEffect(() => {
      if (!noSync) {
        setValue(valueArg);
      }
    }, [valueArg, noSync]);

    const handleInput = (event) => {
      if (onChange) {
        const value =
          event.target.innerText === "\n" ? "" : event.target.innerText;

        onChange(value);
      }
    };

    return (
      <span
        contentEditable
        onInput={handleInput}
        dangerouslySetInnerHTML={{ __html: value }}
        ref={ref}
        {...rest}
      />
    );
  },
);
