import { Response } from "miragejs";

export default function routes(server) {
  server.get("/agreement/", (schema, request) => {
    const authString = request.requestHeaders.Authorization ?? "";
    const token = authString.split(" ")[1];
    if (!token) {
      return new Response(
        401,
        {},
        {
          detail: "Not logged in",
        },
      );
    }

    const theLatestAgreement = schema.all("agreement").models.slice().pop();

    return theLatestAgreement ?? new Response(404, {});
  });
}
