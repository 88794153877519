import React, { useState } from "react";
import { ReactComponent as ForwardIcon } from "../icons/forward.svg";
import Loader from "./Loader";

import "./SenderLayout.scss";

const scope = `SenderLayout`;

function SenderLayout({
  shouldDisable,
  className,
  onSubmit,
  labelFor,
  children,
  ...props
}) {
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSending(true);

    try {
      return await onSubmit(event);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <form
      className={`${className ?? ""} ${scope}`}
      onSubmit={handleSubmit}
      {...props}
    >
      <label className={`${scope}-field`} for={labelFor}>
        {children}
      </label>

      {isSending || shouldDisable ? (
        <Loader size={56} className={`${scope}-spinner`} />
      ) : (
        <button className={`${scope}-send`} type="submit" aria-label="Send">
          <ForwardIcon />
        </button>
      )}
    </form>
  );
}

export default SenderLayout;
