export function defaultScenario(server) {
  server.create("agreement", {
    id: "1",
    version: "1.0",
    text: `### Some title
First paragraph.

Second paragraph.`,
  });

  const user1 = server.create("user", {
    username: "bastian.schlenker",
    first_name: "Bastian",
    last_name: "Schlenker",
    agreement_accepted: true,
    picture_url: "/fake_user.png",
  });

  const user2 = server.create("user", {
    username: "user2",
    first_name: "Second",
    last_name: "User",
    agreement_accepted: false,
    picture_url: "/fake_user.png",
  });

  server.create("chatbot-model", {
    id: "gpt-3.5-turbo",
    name: "GPT-3.5 Turbo",
    use_internal_data: true,
  });

  server.create("chatbot-model", {
    id: "gpt-4.0-0125-preview",
    name: "GPT-4.0",
  });

  server.createList("predefined-prompt-category", 5);
  server.createList("predefined-prompt", 40, {
    created_by: user2.id,
    is_public: true,
  });

  server.create("predefined-prompt", {
    title: "Prompt #1",
    instructions: `This is a prompt instruction with {variable1} and {variable2}.

Second paragraph. It contains a couple of the variable occurrences: {variable3} and {variable3} once again. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.

{spaces are also supported}.

A multipline paragraph without any variables. Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
    is_public: true,
    created_by: user1.id,
  });

  server.create("predefined-prompt", {
    title: "Prompt #2",
    instructions:
      "This is a prompt instruction with {variable1} and {variable2}.",
    is_public: false,
    created_by: user1.id,
  });

  server.create("predefined-prompt", {
    title: "LIGANOVAloves (TKIM)",
    description: "LIGANOVAloves (TKIM) description",
    instructions: `This is a prompt instruction with {variable1} and {variable2}`,
    is_public: false,
    created_by: user1.id,
  });

  const inactiveBotModel = server.create("chatbot-model", {
    id: "inactive-model-with-internal-data",
    name: "inactive model with internal data",
    use_internal_data: true,
    visible: false,
  });

  server.createList("conversation", 40);

  server.create("conversation", {
    title: "With inactive bot model and internal data",
    chatbot_model_id: inactiveBotModel.id,
  });

  server.create("conversation", {
    title: "A tile title which is large enough (2X2)",
  });

  server.create("conversation", {
    title: "A tile (1x1)",
  });
  // the latest conversation is shown at the top
  const conversation = server.create("conversation", {
    title: "A long conversation (1x2)",
  });
  server.createList("question", 50, {
    conversation_id: conversation.id,
  });

  const questionWithAnswerError = server.create("question", {
    text: "Answer error demo",
    conversation_id: conversation.id,
  });

  server.create("answer", {
    error_message: "An error occurred",
    questionId: questionWithAnswerError.id,
    is_best: false,
  });

  server.create("answer", {
    error_message:
      "Error code: 401 - {'error': {'message': 'Incorrect API key provided: sk-TaEP5******************************************C111. You can find your API key at https://platform.openai.com/account/api-keys.', 'type': 'invalid_request_error', 'param': None, 'code': 'invalid_api_key'}}",
    questionId: questionWithAnswerError.id,
    is_best: true,
  });

  const lastQuestion = server.create("question", {
    text: "Last question",
    conversation_id: conversation.id,
  });

  server.create("answer", {
    text: "Regenerated answer with related files",
    questionId: lastQuestion.id,
    is_best: true,
    related_files: [
      {
        id: 1,
        name: "An Untitled document",
        page_numbers: [1, 2],
        url: "about:blank",
      },
      {
        id: 2,
        name: "Another Untitled document",
        page_numbers: [7],
        url: "about:blank",
      },
    ],
  });
}
