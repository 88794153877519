import React from "react";
import PromptsForm from "../../components/Prompts/Form";
import { authRequest, baseUrl } from "../../utils/NetworkUtils";
import TileAnimateable from "../../components/TileAnimateable";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

function PromptsNewPage() {
  const [skipAnimation, setSkipAnimation] = React.useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const submit = async (data) => {
    setSkipAnimation(true);

    try {
      await new Promise((resolve) =>
        requestAnimationFrame(async () => {
          const prompt = await authRequest(
            `${baseUrl}/api/predefined-prompts/`,
            {
              method: "POST",
              body: JSON.stringify(data),
            },
          );

          // enforce list to refresh
          navigate(`/prompts/${prompt.id}/edit`, {
            state: { newPrompt: prompt },
          });

          toast.success("Prompt has been created successfully.", {
            theme: "dark",
            position: "top-center",
          });

          queryClient.invalidateQueries({ queryKey: ["predefined-prompts"] });

          resolve();
        }),
      );
    } catch (error) {
      setSkipAnimation(false);
    }
  };

  return (
    <TileAnimateable
      title={"New Template"}
      skipAnimation={skipAnimation}
      sourceElement={() => {
        // TODO: remove one of the [data-prompt-tile-new] so the animation works properly
        // right now, we hide the quickstart section with a "New Prompt" tile inside,
        // this leads to an invalid sourceElement targeted when doing the animation
        return document.querySelector(`[data-prompt-tile-new]`);
      }}
      returnUrl="/prompts"
      key="Prompts-new"
    >
      <PromptsForm onSubmit={submit} key="Prompts-new" />
    </TileAnimateable>
  );
}

export default PromptsNewPage;
