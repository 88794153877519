import React, { useState } from "react";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as XIcon } from "../../icons/x-lg.svg";
import { ReactComponent as CheckIcon } from "../../icons/check-lg.svg";

import "./InlineEditable.scss";

const scope = "ChatInlineEditable";

export function InlineEditable({ value, save, maxLength, placeholder }) {
  const [isEditing, setIsEditing] = useState(false);
  const [interactiveElement, setInteractiveElement] = useState(null);
  const [bufferedValue, setBufferedValue] = useState(value);
  const canEdit = typeof save === "function";

  const onChange = (e) => {
    const { value } = e.target;
    setBufferedValue(value);
  };

  const submit = async (e) => {
    e.preventDefault();

    const { value } = interactiveElement;

    return save(value).then(() => {
      setIsEditing(false);
    });
  };

  const startEditing = () => {
    setIsEditing(true);

    setTimeout(() => {
      interactiveElement.focus();
    }, 100);
  };

  return (
    <form
      className={scope}
      onSubmit={submit}
      data-edit-mode={isEditing ? true : null}
      data-has-value={!!bufferedValue ? true : null}
    >
      <div
        className={`${scope}-editable`}
        onDoubleClick={canEdit ? startEditing : null}
      >
        <span className={`${scope}-placeholder`}>
          {bufferedValue || placeholder || " "}
        </span>

        <input
          className={`${scope}-input`}
          value={bufferedValue}
          onInput={onChange}
          ref={setInteractiveElement}
          readOnly={!isEditing ? true : null}
          maxLength={maxLength}
        />
      </div>

      {isEditing ? (
        <>
          <button className={`${scope}-save`}>
            <CheckIcon />
          </button>

          <button
            className={`${scope}-cancel`}
            onClick={() => {
              setIsEditing(false);
              setBufferedValue(value);
            }}
          >
            <XIcon />
          </button>
        </>
      ) : canEdit ? (
        <button
          className={`${scope}-edit`}
          onClick={startEditing}
          aria-label="Edit"
        >
          <EditIcon />
        </button>
      ) : null}
    </form>
  );
}
