import { useState } from "react";
import "./UserAgreement.scss";
import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import { useQuery } from "@tanstack/react-query";
import { authRequest, baseUrl } from "../utils/NetworkUtils";
import { marked } from "marked";
import Loader from "./Loader";
import { ReactComponent as ExclamationIcon } from "../icons/exclamation.svg";

const scope = "UserAgreement";

function UserAgreement({ className, onAccept, ...props }) {
  const [isChecked, setIsChecked] = useState(false);

  const accept = async () => {
    await authRequest(`${baseUrl}/current-user/accept-agreement/`, {
      method: "PATCH",

      body: JSON.stringify({
        agreement_id: data.id,
      }),
    });

    onAccept();
  };

  const { data, isError } = useQuery({
    queryKey: ["user-agreement"],
    retry: false,
    queryFn: () => {
      return authRequest(`${baseUrl}/agreement/`);
    },
  });

  return (
    <div className={`${className ?? ""} ${scope}`} {...props}>
      <h3 id={`${scope}-title`}>User Agreement</h3>
      <div className={`${scope}-body`} aria-labelledby={`${scope}-title`}>
        {data?.text ? (
          <div
            className={`${scope}-text`}
            dangerouslySetInnerHTML={{
              __html: marked(data?.text),
            }}
          />
        ) : isError ? (
          <div className={`${scope}-error`}>
            <div className={`${scope}-errorIcon`}>
              <ExclamationIcon />
            </div>
            Error loading agreement text
          </div>
        ) : (
          <div className={`${scope}-loading`}>
            <Loader /> Loading...
          </div>
        )}

        <div className={`${scope}-actions`}>
          <div className={`${scope}-checkbox`}>
            <input
              type="checkbox"
              id="user-agreement"
              checked={isChecked}
              disabled={!data?.text}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
            />
            <label htmlFor="user-agreement">
              I agree with this and will follow the rules.
            </label>
          </div>

          <button
            className={`${scope}-continueButton`}
            disabled={isChecked ? null : true}
            onClick={accept}
          >
            Confirm <ArrowIcon className={`${scope}-arrow`} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserAgreement;
