import React, { useState, useEffect, Suspense } from "react";
import {
  Link,
  useLoaderData,
  useNavigate,
  useOutlet,
  useLocation,
  useMatches,
} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import TileAnimateable from "../../components/TileAnimateable";
import { authRequest, baseUrl } from "../../utils/NetworkUtils";
import ChatStarterForm from "../../components/Prompts/ChatStarterForm";
import { authProvider } from "../../utils/auth";
import { v4 } from "uuid";
import { AnimatePresence } from "framer-motion";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";

import "./ChatStarter.scss";

const questionsUrl = `${baseUrl}/api/questions/`;

export function loader(route) {
  return authRequest(`${baseUrl}/api/predefined-prompts/${route.params.id}/`);
}

const scope = `ChatStarter`;
const returnPath = "/prompts";

function ChatStarter() {
  const matches = useMatches();
  const navigate = useNavigate();
  const animatedOutlet = useOutlet();
  const location = useLocation();
  const queryClient = useQueryClient();
  const liveData = useLoaderData();
  const [predefinedPrompt, setPredefinedPrompt] = useState(useLoaderData());
  const authoredByMe = predefinedPrompt.created_by === authProvider.user?.id;

  const animationMatch = matches[4]?.pathname ?? "index";

  const postQuestion = (text, conversationId) => {
    const newAnswerId = v4();

    return authRequest(questionsUrl, {
      method: "POST",
      body: JSON.stringify({
        question: text,
        conversation_id: conversationId,
        answer_id: newAnswerId,
      }),
    });
  };

  const submit = async (instructions) => {
    const conversation = await authRequest(baseUrl + "/api/conversations/", {
      method: "POST",
      body: JSON.stringify({
        question: instructions,
        predefined_prompt_id: predefinedPrompt.id,
      }),
    });

    await postQuestion(instructions, conversation.id);

    queryClient.setQueryData(["conversations"], () => {
      return {
        pages: [],
        pageParams: [],
      };
    });

    return navigate(`/chats/${conversation.id}`, {
      state: {
        newConversation: {
          ...conversation,
          question: instructions,
        },
        from: "new-chat",
      },
    });
  };

  useEffect(() => {
    if (liveData) {
      setPredefinedPrompt(liveData);
    }
  }, [liveData]);

  return (
    <>
      <TileAnimateable
        className={scope}
        key={`PredefinedPrompt-startPage${predefinedPrompt.id}`}
        sourceElement={() => {
          const source = document.querySelector(
            `[data-prompt-tile-id="${predefinedPrompt.id}"]`,
          );
          return source;
        }}
        returnUrl={returnPath}
        title={
          <div className={`${scope}-title`}>
            {predefinedPrompt.title}

            {authoredByMe ? (
              <Link
                className={`${scope}-edit`}
                to={`/prompts/${predefinedPrompt.id}/edit`}
                state={{ from: location.pathname }}
              >
                <EditIcon aria-label="Edit" />
              </Link>
            ) : null}
          </div>
        }
      >
        {predefinedPrompt.description ? (
          <p className={`${scope}-description`}>
            {predefinedPrompt.description}
          </p>
        ) : null}

        <ChatStarterForm
          className={`${scope}-form`}
          data={predefinedPrompt}
          submit={submit}
          key={`PredefinedPrompt-startForm${predefinedPrompt.id}`}
        />
      </TileAnimateable>

      <AnimatePresence initial={false} mode="wait">
        <Suspense key={animationMatch} fallback={<div>Loading...</div>}>
          {animatedOutlet}
        </Suspense>
      </AnimatePresence>
    </>
  );
}

export default ChatStarter;
