import { routeTimeout } from "../utils";

export default function routes(server) {
  server.get(
    "/predefined-prompt-categories/",
    (schema) => {
      const filtered = schema.all("predefined-prompt-category");

      return filtered;
    },
    routeTimeout(100),
  );
}
