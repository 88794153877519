import { Response } from "miragejs";
import { routeTimeout } from "../utils";

export default function routes(server) {
  server.post("/login", (schema, request) => {
    const username = request.requestBody.get("username");
    const user = schema.findBy("user", { username: username });
    if (!user) {
      return new Response(
        401,
        {},
        {
          detail: "Invalid credentials",
        },
      );
    }

    return new Response(
      200,
      {},
      {
        token: "1234567890",
      },
    );
  });

  server.get(
    "/current-user",
    (schema, request) => {
      const authString = request.requestHeaders.Authorization ?? "";
      const token = authString.split(" ")[1];
      if (!token) {
        return new Response(
          401,
          {},
          {
            detail: "Not logged in",
          },
        );
      }

      return schema.first("user");
    },
    routeTimeout(100),
  );

  server.patch("/current-user/accept-agreement/", (schema, request) => {
    const authString = request.requestHeaders.Authorization ?? "";
    const token = authString.split(" ")[1];
    if (!token) {
      return new Response(
        401,
        {},
        {
          detail: "Not logged in",
        },
      );
    }

    const { agreement_id } = JSON.parse(request.requestBody);
    if (!agreement_id) {
      return new Response(
        400,
        {},
        {
          detail: "agreement_id is required",
        },
      );
    }

    const user = schema.first("user");
    user.update({
      agreement_accepted: true,
      agreement_id,
    });

    return user;
  });
}
