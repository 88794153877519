import { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ListIcon } from "../icons/list.svg";

import "./ActionsDropdown.scss";

const scope = "ActionsDropdown";

export default function ActionsDropdown({
  className,
  from,
  options,
  collapsedIcon,
  reverse,
  ...props
}) {
  const [expanded, setExpanded] = useState(false);

  const [firstOption, ...restOptions] = options;

  const collapse = (e) => {
    e.stopPropagation();
    setExpanded(false);
  };

  const expand = (e) => {
    e.stopPropagation();
    setExpanded(true);
  };

  return (
    <div
      className={`${className ?? ""} ${scope}`}
      data-expanded={expanded ? true : null}
      data-reverse={reverse ? true : null}
      onMouseEnter={expand}
      onMouseLeave={collapse}
      onClick={expand}
      {...props}
    >
      <div className={`${scope}-wrapper`}>
        <div
          className={`${scope}-overlay`}
          onClick={collapse}
          onMouseEnter={collapse}
        ></div>

        <div className={`${scope}-body`}>
          {reverse && (
            <Group from={from} options={restOptions} reverse={true} />
          )}

          {expanded ? (
            <Option from={from} option={firstOption} />
          ) : (
            <Option
              from={from}
              option={{ ...firstOption, icon: collapsedIcon ?? ListIcon }}
            />
          )}

          {!reverse && <Group from={from} options={restOptions} />}
        </div>
      </div>
    </div>
  );
}

function Group({ from, options, reverse, ...props }) {
  const sortedOptions = reverse ? options.slice().reverse() : options;

  return (
    <div className={`${scope}-options`} {...props}>
      {sortedOptions.map((option, index) =>
        option.options ? (
          <Group
            key={`group-${index}`}
            from={from}
            options={option.options}
            reverse={reverse}
          />
        ) : (
          <Option key={option.label} from={from} option={option} />
        ),
      )}
    </div>
  );
}

function Option({ from, option, ...props }) {
  return (
    <Link
      className={`${scope}-link`}
      to={option.to}
      query={option.query}
      state={{ from, ...option.state }}
      aria-label={option.label}
      data-highlight={option.variants?.includes("highlight") ? true : null}
      {...props}
    >
      <div className={`${scope}-linkText`}>{option.label}</div>
      <div className={`${scope}-linkIcon`}>
        <option.icon />
      </div>
    </Link>
  );
}
