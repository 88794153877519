import "./Index.scss";
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Suspense,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  useLocation,
  useOutlet,
  useSearchParams,
  Link,
  useMatches,
} from "react-router-dom";
import { baseUrl, authRequest } from "../../utils/NetworkUtils";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import debounce from "lodash.debounce";
import Logo from "../../components/Logo";
import Search from "../../components/Overview/Search";
import Loader from "../../components/Loader";
import Tile from "../../components/Tile";
import InfiniteScroll from "react-infinite-scroller";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import { ReactComponent as XIcon } from "../../icons/x-lg.svg";
import MultiChoice from "../../components/MultiChoice";
import navItems, {
  shouldHide as shouldHideAppNav,
} from "../../utils/navigation";
import ActionsDropdown from "../../components/ActionsDropdown";
import { useMediaQuery } from "@react-hook/media-query";
import LogoutButton from "../../components/LogoutButton";
import LigaOS from "ln-ember-toolkit/addon/utils/liga-os";

const hasLigaOS = LigaOS.isLigaOsPresent();

const pageSize = 15;
// 1-based indexing is encouraged by the API
const firstPage = 1;

const scope = "PromptsIndex";

function qpStringValueToArray(qpValue) {
  return (
    qpValue
      ?.split(",")
      .map((v) => v.trim())
      .filter(Boolean) ?? []
  );
}

export default function PromptsIndex() {
  const isMobile = useMediaQuery("only screen and (max-width: 768px)");
  const [hideHeader, setHideHeader] = useState(null);
  const [scrollableElement, setScrollableElement] = useState(null);
  const [previousScrollTop, setPreviousScrollTop] = useState(0);
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [qps, setQps] = useSearchParams();
  const [search, setSearch] = useState(qps.get("search") || "");
  const [categories, setCategories] = useState(
    qpStringValueToArray(qps.get("category_ids")),
  );
  const [isPublic, setIsPublic] = useState(
    qpStringValueToArray(qps.get("is_public")),
  );
  const location = useLocation();
  const matches = useMatches();
  const animationMatch = matches[3]?.pathname ?? "index";
  const animatedOutlet = useOutlet();

  const { data: categoryOptions } = useQuery({
    queryKey: ["predefined-prompt-categories"],
    queryFn: async () => {
      const res = await authRequest(
        `${baseUrl}/api/predefined-prompt-categories/`,
      );

      return res.results;
    },
  });

  const filters = [
    {
      name: "is_public",
      options: [
        { label: "Public", value: "true" },
        { label: "Private", value: "false" },
      ],
    },
    categoryOptions?.length > 0 && {
      name: "category_ids",
      options: categoryOptions.map((option) => ({
        label: option.title,
        value: option.id,
      })),
    },
  ].filter(Boolean);

  const filterValues = { is_public: isPublic, category_ids: categories };

  const navOptions = [
    {
      to: "/prompts/new",
      state: { from: location.pathname },
      label: "New Template",
      variants: ["highlight"],
      icon: PlusIcon,
    },
  ].concat(isMobile && !shouldHideAppNav ? [{ options: navItems() }] : []);

  const onScroll = useCallback(
    (e) => {
      setPreviousScrollTop(e.target.scrollTop);

      if (e.target.scrollTop - previousScrollTop < 0) {
        setHideHeader(false);
      } else {
        const reachedScreenThreshold = e.target.scrollTop > 200;

        setHideHeader(reachedScreenThreshold);
      }
    },
    [previousScrollTop, setPreviousScrollTop, setHideHeader],
  );

  const load = async (queryContext) => {
    const searchString = new URLSearchParams({
      page: queryContext.pageParam,
      pageSize,
      ...(search ? { search } : undefined),
      ...(categories.length > 0 ? { category_ids: categories.join(",") } : {}),
      // send only if there is one value. 0 or 2(true and false) values means anything matches
      ...(isPublic.length === 1 ? { is_public: isPublic[0] } : {}),
    });

    const data = await authRequest(
      `${baseUrl}/api/predefined-prompts/?${searchString}`,
    );
    return data.results;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
    isRefetching,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["predefined-prompts"],
    queryFn: load,
    initialPageParam: firstPage,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPageParam === undefined) {
        return firstPage;
      }

      if (lastPage.length < pageSize) {
        return undefined;
      }

      return lastPageParam + 1;
    },
  });

  const reloadLater = useMemo(() => debounce(refetch, 500), [refetch]);
  useEffect(() => {
    return () => {
      reloadLater.cancel();
    };
  }, [reloadLater, refetch]);

  const prompts = useMemo(() => {
    return data?.pages.flatMap((page) => page);
  }, [data]);

  const onSearch = (value) => {
    setSearch(value);
    setQps({ search: value ?? "" }, { preventScrollReset: true });
    reloadLater();
  };

  const changeFilters = (filters) => {
    setCategories(filters.category_ids);
    setIsPublic(filters.is_public);

    setQps(
      (prev) => {
        return {
          ...prev,
          category_ids: filters.category_ids.join(","),
          is_public: filters.is_public.join(","),
        };
      },
      { preventScrollReset: true },
    );

    // give state time to update before refetching
    requestAnimationFrame(() => {
      refetch();
    });
  };

  const onChangeFilter = (event) => {
    const formData = new FormData(event.target.form);
    const category_ids = formData.getAll("category_ids");
    const is_public = formData.getAll("is_public");

    changeFilters({ category_ids, is_public });
  };

  const removeFilter = (group, option) => {
    const newValues = {
      ...filterValues,
      [group.name]: filterValues[group.name].filter(
        (value) => value !== option.value,
      ),
    };

    changeFilters(newValues);
  };

  return (
    <>
      <section
        className={scope}
        data-no-side-controls={hideHeader ? true : null}
        data-mobile={isMobile ? true : null}
      >
        <div
          className={`${scope}-scrollable`}
          ref={setScrollableElement}
          onScroll={onScroll}
        >
          <div
            className={`${scope}-header`}
            data-search-expanded={isSearchExpanded ? true : null}
          >
            <div className={`${scope}-logo`}>
              <button
                className={`${scope}-logoLink`}
                onClick={() => onSearch(null)}
              >
                <Logo className={`${scope}-logoImage`} />
              </button>
            </div>

            <Search
              search={search}
              onSearch={onSearch}
              className={`${scope}-search`}
              onToggleIsExpanded={setIsSearchExpanded}
              placeholder="Search templates"
            />

            {!isMobile && navOptions?.length > 0 && (
              <ActionsDropdown
                className={`${scope}-actions`}
                collapsedIcon={PlusIcon}
                data-prompt-tile-new
                from={location.pathname}
                options={navOptions}
                aria-label="Actions"
              />
            )}

            {!hasLigaOS && isMobile && (
              <LogoutButton className={`${scope}-logout`} />
            )}
          </div>

          <div className={`${scope}-gridHeader`} id="QuickstartsTitle">
            <div className={`${scope}-gridTitle`}>Quickstarts</div>
          </div>

          <div className={`${scope}-quickstarts`}>
            <Link
              to={`/prompts/new`}
              className={`${scope}-new`}
              data-prompt-tile-new
              aria-label="New Template"
              state={{ from: location.pathname }}
            >
              <PlusIcon className={`${scope}-newIcon`} />
            </Link>
          </div>

          <div className={`${scope}-gridHeader`} id="Prompts">
            <div className={`${scope}-gridTitle`}>Templates</div>
            <form className={`${scope}-filters`} id="PromptsFilter">
              <MultiChoice
                form="PromptsFilter"
                trigger={
                  <div className={`${scope}-filterTrigger`}>
                    <SettingsIcon /> Filter
                  </div>
                }
                options={["Public", "Private"]}
                onChange={onChangeFilter}
                groups={filters}
                values={filterValues}
              />
            </form>
            <div className={`${scope}-selectedFilters`}>
              {filters.flatMap((filterGroup) => {
                return filterValues?.[filterGroup.name]?.map((value) => {
                  const option = filterGroup.options.find(
                    (option) => option.value === value,
                  );
                  return (
                    <div
                      className={`${scope}-selectedFilter`}
                      key={`${option.label}-${option.value}`}
                    >
                      {option.label}

                      <button
                        className={`${scope}-removeFilter`}
                        onClick={() => removeFilter(filterGroup, option)}
                      >
                        <XIcon />
                      </button>
                    </div>
                  );
                });
              })}
            </div>
          </div>

          <div className={`${scope}-body`}>
            <AnimatePresence>
              {(isRefetching || isLoading) && (
                <AnimatedLoader className={`${scope}-reloading`} />
              )}
            </AnimatePresence>

            {!isRefetching && prompts?.length === 0 && !isLoading ? (
              <p className={`${scope}-noResults`}>No results found</p>
            ) : null}

            <InfiniteScroll
              className={`${scope}-list`}
              pageStart={0}
              loadMore={() => {
                if (!isFetchingNextPage) {
                  return fetchNextPage();
                }
              }}
              hasMore={hasNextPage}
              useWindow={false}
              getScrollParent={() => scrollableElement}
              role="feed"
              aria-labelledby="Prompts"
              aria-busy={
                isLoading || isRefetching || isFetchingNextPage ? true : null
              }
            >
              <AnimatePresence key="PromptsAnimatePresence">
                {!isRefetching &&
                  prompts?.length > 0 &&
                  prompts.map((e) => {
                    const shouldHighlight = Boolean(e.use_internal_data);

                    return (
                      <motion.div
                        className={`${scope}-item`}
                        key={e.id}
                        initial={{
                          opacity: 0,
                        }}
                        animate={{
                          opacity: 1,
                          transition: { duration: 0.3, delay: 0.3 },
                        }}
                        exit={{ opacity: 0, transition: { duration: 0.3 } }}
                        data-should-highlight={shouldHighlight ? true : null}
                        role="article"
                      >
                        <Tile
                          className={`${scope}-tile`}
                          data-prompt-tile-id={e.id}
                          description={e.description}
                          state={{ from: location.pathname }}
                          title={e.title}
                          to={`/prompts/${e.id}`}
                          userId={e.is_public ? e.created_by : null}
                        />
                      </motion.div>
                    );
                  })}
              </AnimatePresence>
            </InfiniteScroll>

            {isMobile && navOptions?.length > 0 && (
              <ActionsDropdown
                className={`${scope}-actions`}
                reverse={true}
                data-prompt-tile-new
                from={location.pathname}
                options={navOptions}
                aria-label="Actions"
              />
            )}

            {isFetchingNextPage && <AnimatedLoader />}
          </div>
        </div>
      </section>

      <AnimatePresence initial={false} mode="popLayout">
        <Suspense key={animationMatch} fallback={<div>Loading...</div>}>
          {animatedOutlet}
        </Suspense>
      </AnimatePresence>
    </>
  );
}

function AnimatedLoader({ ...props }) {
  return (
    <motion.div
      className={`${scope}-loading`}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: { duration: 0.2 },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.2 },
      }}
      {...props}
    >
      <Loader /> Loading ...
    </motion.div>
  );
}
