import AppSerializer from "../serializer";

export default class QuestionSerializer extends AppSerializer {
  serializeIds = "always";

  include = ["answers"];

  embed(key) {
    return key === "answers";
  }

  serialize(response, ...args) {
    const result = super.serialize(response, ...args);
    if (this.isCollection(response)) {
      result.results = result.results.map((responseItem) => {
        const answers = response.models.find((c) => c.id === responseItem.id)
          .answers.models;

        return {
          ...responseItem,
          answers: [...answers.map((a) => ({ ...a.attrs }))],
        };
      });
    }

    return result;
  }
}
