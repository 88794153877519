import { useState, useEffect, useCallback } from "react";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as XIcon } from "../../icons/x-lg.svg";

import "./Search.scss";

const scope = "OverviewSearch";

export default function OverviewSearch({
  search,
  onSearch,
  className,
  onToggleIsExpanded,
  placeholder = "Search",
}) {
  const [expanded, _setExpanded] = useState(Boolean(search));

  const setExpanded = useCallback(
    (value) => {
      _setExpanded(value);

      onToggleIsExpanded?.(value);
    },
    [onToggleIsExpanded],
  );

  const onToggleClick = () => {
    setExpanded(true);
  };

  const change = (e) => {
    onSearch(e.target.value);
  };

  const reset = (e) => {
    e.preventDefault();

    if (search) {
      onSearch("");
    }

    setExpanded(false);
  };

  useEffect(() => {
    if (search === null) {
      setExpanded(false);
    }
  }, [search, setExpanded]);

  return (
    <label
      className={`${className} ${scope}`}
      data-expanded={expanded ? true : null}
      data-has-value={search ? true : null}
      role="search"
    >
      <div className={`${scope}-box`}>
        <div
          className={`${scope}-toggle`}
          onClick={!expanded ? onToggleClick : null}
        >
          <SearchIcon />
        </div>

        <input
          className={`${scope}-input`}
          type="search"
          value={search ?? ""}
          onChange={change}
          placeholder={placeholder}
          spellCheck={false}
        />

        {expanded ? (
          <button className={`${scope}-reset`} onClick={reset}>
            <XIcon />
          </button>
        ) : null}
      </div>
    </label>
  );
}
