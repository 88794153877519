import { useState, useEffect } from "react";
import TextareaAutosize from "react-textarea-autosize";

import "./QuestionForm.scss";
import SenderLayout from "../SenderLayout";
import { isTouchEnabled } from "../../utils/device";

const scope = `ChatQuestionForm`;

function QuestionForm({
  id,
  currentChatModel,
  onChange,
  question,
  disable,
  className,
  onSubmit,
  ...props
}) {
  const [inputRef, setInputRef] = useState(null);

  const isTouchDevice = isTouchEnabled();

  const handleTextareaKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      event.target.form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  const maybeAutofocus = () => {
    if (!isTouchDevice && inputRef) {
      setTimeout(() => inputRef.focus(), 50);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const text = question.trim();
    if (!text) {
      return;
    }

    await onSubmit(text);

    maybeAutofocus();
  };

  useEffect(() => {
    maybeAutofocus();
  });

  return (
    <SenderLayout
      className={`${className ?? ""} ${scope}`}
      onSubmit={handleSubmit}
      shouldDisable={disable}
      {...props}
    >
      <TextareaAutosize
        cacheMeasurements
        value={question}
        onChange={onChange}
        onKeyPress={handleTextareaKeyPress}
        readOnly={disable}
        disabled={disable}
        maxRows={10}
        aria-label="Type a message"
        placeholder={currentChatModel ? `Ask "${currentChatModel.name}"` : null}
        ref={setInputRef}
      />
    </SenderLayout>
  );
}

export default QuestionForm;
