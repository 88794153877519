import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { useQuery } from "@tanstack/react-query";
import { authRequest, baseUrl } from "../../utils/NetworkUtils";
import { ReactComponent as TrashIcon } from "../../icons/trash.svg";

import "./Form.scss";

const scope = `PromptsForm`;
const disablePublicPrompts = process.env.REACT_APP_NO_PUBLIC_PROMPTS === "true";

function PromptsForm({ data, onSubmit, onDelete }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [model, updateModel] = useState({
    title: null,
    description: null,
    instructions: null,
    files: [],
    use_internal_data: false,
    is_public: false,
    category_id: null,
    ...data,
  });

  const { data: categoryOptions, isLoading: isLoadingCategories } = useQuery({
    queryKey: ["predefined-prompt-categories"],
    queryFn: async () => {
      const res = await authRequest(
        `${baseUrl}/api/predefined-prompt-categories/`,
      );

      return res.results;
    },
  });

  const handleStringBooleanChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({ ...prev, [name]: value === "true" }));
  };

  const handleTextChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({ ...prev, [name]: value }));
  };

  const _onDelete = () => {
    setIsSubmitting(true);

    return onDelete(model).finally(() => {
      setIsSubmitting(false);
    });
  };

  const _onSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    return onSubmit({
      ...model,
      ...(disablePublicPrompts ? { is_public: undefined } : null),
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const onReset = (event) => {
    event.preventDefault();

    return updateModel(data);
  };

  return (
    <form
      className={`${scope}`}
      onSubmit={_onSubmit}
      onReset={onReset}
      data-submitting={isSubmitting || null}
    >
      <label>
        <span className={`${scope}-label`}>Title *</span>

        <input
          type="text"
          value={model.title ?? ""}
          name="title"
          aria-label="Title"
          placeholder={"Title"}
          onChange={handleTextChange}
          required
        />
      </label>

      <div className={`${scope}-body`}>
        <label>
          <span className={`${scope}-label`}>Description</span>

          <input
            value={model.description ?? ""}
            name="description"
            placeholder={"Description"}
            onChange={handleTextChange}
          />
        </label>

        <label>
          <span className={`${scope}-label`}>Instructions *</span>

          <TextareaAutosize
            cacheMeasurements
            value={model.instructions ?? ""}
            maxRows={10}
            name="instructions"
            placeholder={
              "My prompt instructions with {variable1} and {variable2}"
            }
            onChange={handleTextChange}
            required
          />
        </label>

        <div className={`${scope}-extras`}>
          <div className={`${scope}-internalKnowledgeSelector`}>
            <span className={`${scope}-label`}>With internal knowledge</span>

            <label>
              <input
                type="radio"
                value={true}
                name="use_internal_data"
                checked={model.use_internal_data}
                onChange={handleStringBooleanChange}
              />
              yes
            </label>

            <label>
              <input
                type="radio"
                value={false}
                name="use_internal_data"
                checked={!model.use_internal_data}
                onChange={handleStringBooleanChange}
              />
              no
            </label>
          </div>

          <div className={`${scope}-publicitySelector`}>
            <span className={`${scope}-label`}>Availability</span>

            <label>
              <input
                type="radio"
                value={false}
                disabled={disablePublicPrompts}
                name="is_public"
                checked={!model.is_public}
                onChange={handleStringBooleanChange}
              />
              Personal
            </label>

            <label>
              <input
                type="radio"
                value={true}
                disabled={disablePublicPrompts}
                name="is_public"
                checked={model.is_public}
                onChange={handleStringBooleanChange}
              />
              Public
            </label>
          </div>

          <div className={`${scope}-categorySelector`}>
            <span className={`${scope}-label`}>Category *</span>

            {isLoadingCategories ? (
              <p>Loading...</p>
            ) : categoryOptions && categoryOptions.length ? (
              categoryOptions.map((option) => (
                <label key={option.id}>
                  <input
                    type="radio"
                    value={option.id}
                    name="category_id"
                    checked={model.category_id === option.id}
                    onChange={handleTextChange}
                    required
                  />
                  {option.title}
                </label>
              ))
            ) : (
              <p>Could not load categories.</p>
            )}
          </div>
        </div>
      </div>

      <div className={`${scope}-actions`}>
        {onDelete && (
          <button
            type="button"
            className={`${scope}-delete`}
            title="Delete Prompt"
            onClick={_onDelete}
            disabled={isSubmitting}
          >
            <TrashIcon />
          </button>
        )}

        <button type="submit" disabled={isSubmitting}>
          Save
        </button>
      </div>
    </form>
  );
}

export default PromptsForm;
