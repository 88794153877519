import { useEffect } from "react";
import { useRouteError, useNavigate } from "react-router-dom";
import { AuthRequiredError } from "../utils/auth";
import LigaOS from "ln-ember-toolkit/addon/utils/liga-os";
import "./ErrorPage.scss";

export default function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    if (error instanceof AuthRequiredError) {
      if (LigaOS.isLigaOsPresent()) {
        LigaOS.notifyAccessDenied();
      } else {
        navigate("/login");
      }
    }
  }, [error, navigate]);

  if (error instanceof AuthRequiredError) {
    return null;
  }

  console.error(error);
  debugger;

  return (
    <div className="ErrorPage">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
