import "./Tile.scss";

import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import { useUser } from "../utils/users";
import UserPicture from "./UserPicture";

const scope = "Tile";

function Tile({ className, title, description, userId, ...props }) {
  const { data: user } = useUser(userId);

  const shouldDisplayUserIcon = Boolean(user);

  return (
    <Link
      className={`${className} ${scope}`}
      title={description ?? null}
      {...props}
    >
      <div className={`${scope}-header`}>
        {shouldDisplayUserIcon && (
          <UserPicture
            className={`${scope}-userIcon`}
            user={user}
            aria-label="User icon"
          />
        )}

        <div className={`${scope}-arrow`}>
          <ArrowIcon className={`${scope}-arrowImage`} />
        </div>
      </div>
      <div className={`${scope}-body`}>
        <h3 className={`${scope}-title`}>{title}</h3>
      </div>
    </Link>
  );
}

export default Tile;
