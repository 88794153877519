import * as ohm from "ohm-js";

const myGrammar = ohm.grammar(String.raw`
  MyGrammar {
    doc = block+
    block = plain | variable
    plain = ( ~( variable ) any)+
    variable = "{" (~"}" any)* "}"
  }
`);

const semantics = myGrammar.createSemantics();
semantics.addOperation("content", {
  _terminal() {
    return this.sourceString;
  },

  _iter(...children) {
    return children.map((c) => c.content());
  },

  plain(a) {
    return ["plain", a.children.map((c) => c.content()).join("")];
  },

  variable(_1, a, _2) {
    return [
      "variable",
      a.children
        .map((c) => c.content())
        .join("")
        .trim(),
    ];
  },
});

export function parse(input) {
  const match = myGrammar.match(input);
  if (match.failed()) {
    return null;
  }

  return semantics(match).content();
}

export function compile(instructions, params) {
  return instructions
    .map(([type, content]) => {
      if (type === "variable") {
        return params[content];
      }

      return content;
    })
    .join("");
}

export function paramNames(parsed) {
  return [
    ...new Set(
      parsed
        ?.filter(([type]) => type === "variable")
        .map(([_, content]) => content) ?? [],
    ),
  ];
}
