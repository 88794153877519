import { Factory } from "miragejs";
import { faker } from "@faker-js/faker";

export default Factory.extend({
  first_name() {
    return faker.person.firstName();
  },

  last_name() {
    return faker.person.lastName();
  },

  username() {
    return faker.internet.userName();
  },

  agreement_accepted() {
    return true;
  },

  picture_url() {
    return "/fake_user.png";
  },
});
