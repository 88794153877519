import { ReactComponent as ChatIcon } from "../icons/chat.svg";
// import { ReactComponent as ArchiveIcon } from "../icons/archive.svg";
import { ReactComponent as PromptsIcon } from "../icons/prompts.svg";

export const shouldHide = process.env.REACT_APP_HIDE_NAV === "true";

export default function navItems() {
  return [
    {
      to: "/chats",
      icon: ChatIcon,
      label: "Chats",
    },
    {
      to: "/prompts",
      icon: PromptsIcon,
      label: "Templates",
    },
    // {
    //   to: "/", // TODO: updates to a valid path
    //   icon: ArchiveIcon,
    //   label: "Archive",
    // },
  ];
}
