import { Response } from "miragejs";
import { routeTimeout } from "../utils";

export default function routes(server) {
  server.get(
    "/conversations",
    (schema, request) => {
      const search = request.queryParams.search ?? "";
      const page = Number(request.queryParams.page ?? 1);
      const pageSize = Number(request.queryParams.pageSize ?? 10);
      const filtered = schema
        .where("conversation", (c) => c.title.includes(search))
        // reverse
        .sort(() => -1);

      const fromIndex = (page - 1) * pageSize;
      return filtered.slice(fromIndex, fromIndex + pageSize);
    },
    routeTimeout(100),
  );

  server.get(
    "/conversations/:id",
    (schema, request) => {
      const id = request.params.id;
      const record = schema.find("conversation", id);
      if (!record) {
        return new Response(404, {}, { detail: "Not found" });
      }

      return record;
    },
    routeTimeout(200),
  );

  server.post(
    "/conversations",
    (schema, request) => {
      const payload = JSON.parse(request.requestBody);
      const title = payload.question.split("\n")[0];
      const chatbot_model_id = payload.chatbot_model_id;
      const predefined_prompt_id = payload.predefined_prompt_id;
      if (!chatbot_model_id && !predefined_prompt_id) {
        const chatbotModel = schema.find("chatbot-model", chatbot_model_id);
        if (!chatbotModel) {
          return new Response(400, {}, { detail: "Chatbot model not found" });
        }
      }

      return server.create("conversation", {
        title,
        chatbot_model_id,
        predefined_prompt_id,
      });
    },
    routeTimeout(2000),
  );

  server.put(
    "/conversations/:id",
    (schema, request) => {
      const id = request.params.id;
      const record = schema.find("conversation", id);
      if (!record) {
        return new Response(404, {}, { detail: "Not found" });
      }

      const { title } = JSON.parse(request.requestBody);

      return record.update({
        title,
      });
    },
    routeTimeout(2000),
  );
}
