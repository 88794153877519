import React from "react";
import ChatComponent from "../components/Chat";

import TileAnimateable from "../components/TileAnimateable";
import { useParams } from "react-router-dom";

export async function newRecord() {
  return {
    isNew: true,
  };
}

function ChatPage() {
  const conversationId = useParams().id;

  return (
    <TileAnimateable
      sourceElement={() => {
        return document.querySelector(
          conversationId
            ? `[data-conversation-id="${conversationId}"]`
            : `[data-conversation-new]`,
        );
      }}
      returnUrl="/chats"
    >
      <ChatComponent conversationId={conversationId} />
    </TileAnimateable>
  );
}

export default ChatPage;
