import LigaOS from "ln-ember-toolkit/addon/utils/liga-os";
import { baseUrl } from "./NetworkUtils";

const hasLigaOS = LigaOS.isLigaOsPresent();

export const LS_TOKEN_KEY = "LIGADATA_ACCESS_TOKEN";

export class AuthRequiredError extends Error {
  constructor(message) {
    super(message);
    this.name = "AuthRequiredError";
  }
}

const restoreToken = () => {
  const existingToken = localStorage.getItem(LS_TOKEN_KEY);
  return existingToken ? "Bearer " + existingToken : "";
};

const storeToken = (value) => {
  if (value === null) {
    localStorage.removeItem(LS_TOKEN_KEY);
  } else {
    localStorage.setItem(LS_TOKEN_KEY, value);
  }
};

export const authProvider = {
  user: null,
  token: null,

  loadToken() {
    if (authProvider.token) {
      return Promise.resolve(authProvider.token);
    }

    if (!authProvider._tokenRequest) {
      authProvider._tokenRequest = Promise.resolve(
        hasLigaOS
          ? LigaOS.getSession().then(({ token }) => token)
          : restoreToken(),
      ).then((token) => {
        if (!token) {
          throw new AuthRequiredError("No token found");
        }

        authProvider.token = token;
        if (token !== restoreToken()) {
          storeToken(token);
        }

        return token;
      });
    }

    return this._tokenRequest;
  },

  async load() {
    const token = await authProvider.loadToken();

    const response = await fetch(`${baseUrl}/current-user/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const res = await response.json();

    if (hasLigaOS) {
      LigaOS.notifyAppLoaded();
    }

    if (response.ok && res) {
      return (authProvider.user = res);
    } else {
      throw new AuthRequiredError(res.message);
    }
  },

  async login(username, password) {
    const body = new FormData();
    body.append("username", username);
    body.append("password", password);

    return fetch(baseUrl + "/login/", { method: "POST", body }).then((res) => {
      if (res.status !== 200) {
        return res.json().then((body) => {
          throw new Error(body.detail);
        });
      } else {
        return res.json().then((body) => {
          authProvider.token = body.token;
          storeToken(body.token);
        });
      }
    });
  },

  logout() {
    authProvider.user = null;
    authProvider.token = null;
    storeToken(null);
  },
};
