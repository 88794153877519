import { routeTimeout } from "../utils";

export default function routes(server) {
  server.get(
    "/chatbot-models",
    (schema) => {
      return schema.all("chatbot-model");
    },
    routeTimeout(100),
  );
}
