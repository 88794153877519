import { useState } from "react";
import "./UserPicture.scss";

const scope = "UserPicture";

export default function UserPicture({ className, user, ...props }) {
  const [hasPicError, setPicError] = useState(false);
  const [hasPicSuccess, setPicSuccess] = useState(false);
  const pic = user.picture_url;
  const status = pic
    ? hasPicSuccess
      ? "success"
      : hasPicError
        ? "error"
        : "loading"
    : "no-pic";

  return (
    <div
      {...props}
      className={`${className ?? ""} ${scope}`}
      role="img"
      data-status={status}
    >
      {userInitials(user)}

      {pic && !hasPicError ? (
        <img
          className={`${scope}-img`}
          src={user.picture_url}
          alt="User"
          onError={() => setPicError(true)}
          onLoad={() => setPicSuccess(true)}
        />
      ) : null}
    </div>
  );
}

function userInitials(user) {
  if (!user) {
    return "n/a";
  }

  if (user.first_name && user.last_name) {
    return (
      user.first_name[0].toLocaleUpperCase() +
      user.last_name[0].toLocaleUpperCase()
    );
  }

  return user.username[0].toLocaleUpperCase();
}
