import { Factory } from "miragejs";
import { faker } from "@faker-js/faker";

export default Factory.extend({
  text() {
    return faker.lorem.sentence({ min: 2, max: 5 });
  },

  afterCreate(question, server) {
    if (!question.answer_id) {
      server.create("answer", {
        question,
      });
    }
  },
});
