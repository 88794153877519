import { Factory } from "miragejs";
import { faker } from "@faker-js/faker";

export default Factory.extend({
  title() {
    return faker.lorem.words({ min: 2, max: 5 });
  },

  instructions() {
    return faker.lorem.paragraph();
  },

  use_internal_data() {
    return faker.datatype.boolean();
  },

  is_public() {
    return faker.datatype.boolean();
  },

  afterCreate(model, server) {
    if (!model.category_id) {
      const someCategory = server.schema.first("predefined-prompt-category");

      model.update({
        category_id: someCategory.id,
      });
    }
  },
});
