import { Factory } from "miragejs";
import { faker } from "@faker-js/faker";

export default Factory.extend({
  text() {
    return faker.lorem.paragraphs({ min: 1, max: 2 });
  },

  is_liked() {
    return null;
  },

  related_files() {
    return [];
  },

  afterCreate(answer, server) {
    if (answer.is_best !== false) {
      server.schema
        .where("answer", {
          questionId: answer.questionId,
        })
        .models.forEach((oldAnswer) => {
          oldAnswer.update({
            is_best: false,
          });
        });

      answer.update({
        is_best: true,
      });
    }
  },
});
