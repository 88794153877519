import { faker } from "@faker-js/faker";
import EventSource, { sources } from "eventsourcemock";
import { baseUrl } from "../../utils/NetworkUtils";
import { routeTimeout, shouldEnableMirage } from "../utils";
import { Response } from "miragejs";

if (shouldEnableMirage()) {
  Object.defineProperty(window, "EventSource", {
    value: EventSource,
  });
}

export default function routes(server) {
  server.get(
    "/answers/:id",
    (schema, request) => {
      return schema.find("answer", request.params.id);
    },
    routeTimeout(0),
  );

  server.patch(
    "/answers/:id/best-answer/",
    (schema, request) => {
      const answer = schema.find("answer", request.params.id);
      answer.question.answers.models.forEach((a) => {
        a.update({ is_best: false });
      });
      answer.update({ is_best: true });

      return new Response(204);
    },
    routeTimeout(0),
  );

  server.patch(
    "/answers/:id/like/",
    (schema, request) => {
      const answer = schema.find("answer", request.params.id);
      const { is_liked } = JSON.parse(request.requestBody);
      answer.update({ is_liked });

      return new Response(204);
    },
    routeTimeout(0),
  );
}

const chunksDelimiter = `"""`;
const errorToken = `"""Error`;

/**
 * Stream answer to the client
 *
 * There is a special DSL for the testing purposes:
 *  - If the question text contains `"""Error`, we will simulate an error streaming event
 *  - If the question text contains `"""`, we will split the text into chunks and stream them one by one
 *
 * @param {import("miragejs/server").Server} server
 */
export async function streamAnswer(answer, questionText) {
  const errorMessage = questionText?.includes(errorToken) ? questionText : null;

  const text = questionText?.includes(chunksDelimiter)
    ? questionText
    : faker.lorem.paragraphs({ min: 1, max: 4 }, "\n\n");

  log("going to stream", text);

  const paragraphs = text.split("\n\n");

  const chunks = paragraphs
    .map((p) => p.split(chunksDelimiter))
    .filter(Boolean)
    .reduce((all, p, index) => {
      const delimiter = index < paragraphs.length - 1 ? "\n\n" : null;

      return [...all, ...p, delimiter].filter(Boolean);
    }, []);

  // wait for EventSource to be registered on the APP side
  const eventSource = await new Promise((resolve) => {
    const interval = setInterval(() => {
      const eventSource =
        sources[`${baseUrl}/api/answers/${answer.id}/events/`];

      if (eventSource) {
        clearInterval(interval);
        resolve(eventSource);
      }
    }, 200);
  });

  eventSource.emitOpen();

  if (errorMessage) {
    setTimeout(() => {
      log("error", errorMessage);
      eventSource.emit(
        "error",
        new MessageEvent("error", {
          data: JSON.stringify({
            message: errorMessage,
          }),
        }),
      );

      answer.update({
        is_pending: false,
        error_message: errorMessage,
      });

      log("close");
      eventSource.emit(
        "close",
        new MessageEvent("close", {
          data: null,
        }),
      );
    }, routeTimeout(2000).timing);
  } else {
    const interval = setInterval(() => {
      const newChunk = chunks.shift();

      answer.update({
        text: [answer.text, newChunk.replace(chunksDelimiter)]
          .filter(Boolean)
          .join(""),
      });

      log("message", newChunk);
      eventSource.emit(
        "message",
        new MessageEvent("message", {
          data: JSON.stringify({
            token: newChunk,
          }),
        }),
      );

      if (chunks.length === 0) {
        answer.update({
          is_pending: false,
        });

        log("close");
        eventSource.emit(
          "close",
          new MessageEvent("close", {
            data: null,
          }),
        );

        clearInterval(interval);
        return;
      }
    }, routeTimeout(2000).timing);
  }
}

function log(name, ...args) {
  console.info(`[streaming] ${name}:`, ...args);
}
