import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useMatches } from "react-router-dom";
import { motion, useAnimate } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";
import { ReactComponent as ForwardIcon } from "../icons/forward.svg";

import "./TileAnimateable.scss";

const scope = `TileAnimateable`;
const TRANSITION_DURATION = 0.3;

// Note, in order to enable the "slidein" animation, transition should have a state `from` property, which is then compared to the `returnUrl` property. If they match, the animation will be triggered.
// For the "slideout" animation, the last pathname in the matches array is compared to the `returnUrl` property. If they match, the animation will be triggered.
function TileAnimateable({
  children,
  className,
  title,
  sourceElement,
  isNew,
  returnUrl,
  BackIcon,
  skipAnimation: _skipAnimation = false,
  ...props
}) {
  const [skipAnimation, setSkipAnimation] = useState(_skipAnimation);
  useEffect(() => {
    setSkipAnimation(_skipAnimation);
  }, [_skipAnimation]);

  const [scopeRef] = useAnimate();
  const [animationName, setAnimationName] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const [from] = useState(location.state?.from ?? null);
  // reset the `from` state after the first render
  // so that animation doesn't happen on page reload
  const { state } = window.history;
  if (state?.usr?.from) {
    const newState = {
      ...state,

      usr: {
        ...state.usr,
        from: null,
      },
    };

    window.history.replaceState(newState, "", window.location.toString());
  }

  const matches = useMatches();
  const shouldAnimateHide = useMemo(
    () => matches[matches.length - 1].pathname === returnUrl,
    [matches, returnUrl],
  );

  const shouldAnimateAppear = useMemo(
    () => from === returnUrl,
    [from, returnUrl],
  );

  const duration = useMemo(
    () => (shouldAnimateHide || shouldAnimateAppear ? TRANSITION_DURATION : 0),
    [shouldAnimateHide, shouldAnimateAppear],
  );

  const escHandler = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      navigate(returnUrl);
    }
  };

  const slideout = () => {
    if (skipAnimation) {
      return null;
    }

    const tileData = {
      x: "100vw",
      height: "100vh",
    };

    const container = document.querySelector(`.App-body`);
    if (container) {
      const parentTile = sourceElement && sourceElement();

      if (parentTile) {
        const containerRect = container?.getBoundingClientRect();
        const tileRect = parentTile?.getBoundingClientRect();
        const width = tileRect.width;
        const height = tileRect.height;
        Object.assign(tileData, {
          "--tileColor": getComputedStyle(parentTile).backgroundColor,
          x: tileRect.x + tileRect.width / 2 - containerRect.x - width / 2,
          y: tileRect.y + tileRect.height / 2 - containerRect.y - height / 2,
          width,
          height,
        });
      }
    }

    return {
      ...tileData,

      display: "flex",

      transitionEnd: {
        display: "none",
      },
    };
  };

  const slidein = () => {
    if (skipAnimation) {
      return null;
    }

    const container = document.querySelector(".App-body");

    const containerRect = container?.getBoundingClientRect();
    return {
      display: "flex",
      x: 0,
      y: 0,
      width: containerRect?.width,
      height: containerRect?.height,
    };
  };

  const variants = {
    slidein,
    slideout,
  };

  useEffect(() => {
    document.addEventListener("keydown", escHandler, false);

    return () => {
      document.removeEventListener("keydown", escHandler, false);
    };
  });

  const animations = {
    initial: "slideout",
    animate: "slidein",
    exit: "slideout",
  };

  return (
    <motion.div
      variants={variants}
      {...animations}
      transition={{
        ease: "easeOut",
        duration: duration,
      }}
      onAnimationStart={(variantName) => {
        setAnimationName(variantName);
        if (scopeRef?.current) {
          scopeRef.current.dataset.animationName = variantName;
        }
      }}
      onAnimationComplete={(variantName) => {
        setAnimationName(null);

        if (scopeRef?.current) {
          scopeRef.current.dataset.animationName = variantName;

          if (variantName === "slidein") {
            // framer-motion issue: it doesn't reset width and height after animation.
            // This leads to the element being stuck in the final animation size
            // while resizing the window.
            setTimeout(() => {
              const rootElement = scopeRef.current;
              if (!rootElement) return;

              // so let's just reset it ourselves
              rootElement.style.width = null;
              rootElement.style.height = null;
            }, 500);
          }
        }
      }}
      className={`${className} ${scope}`}
      data-animation-name={animationName || null}
      data-is-new={isNew || null}
      style={{ "--animationDuration": duration + "s" }}
      ref={scopeRef}
      {...props}
    >
      <div className={`${scope}-header`}>
        <div className={`${scope}-back--mobile`}>
          <Link to={returnUrl} className={`${scope}-backLink`}>
            <ForwardIcon role="img" />
          </Link>
        </div>

        {title ? <h2 className={`${scope}-title`}>{title}</h2> : null}

        <div className={`${scope}-back`}>
          <Link
            to={returnUrl}
            className={`${scope}-backLink`}
            aria-label="Back"
          >
            {BackIcon ? BackIcon : <ArrowIcon />}
          </Link>
        </div>
      </div>

      <div className={`${scope}-body`}>{children}</div>
    </motion.div>
  );
}

export default TileAnimateable;
